import React from 'react';
import { useTranslation } from 'react-i18next';

const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content comfirmation-modal" onClick={(e) => e.stopPropagation()}>
        <h2>{t('confirmation')}</h2>
        <p>{message}</p>
        <div className="modal-buttons">
          <button onClick={onConfirm} className="confirm-button">{t('yes')}</button>
          <button onClick={onClose} className="cancel-button">{t('no')}</button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;