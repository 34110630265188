import React from 'react';

const TabImages = ({ item, handleImageClick }) => (
  <div className="image-gallery">
    {item.images && item.images.length > 0 ? (
      item.images.map((image, idx) => (
        <img 
          key={idx} 
          src={image} 
          alt={`image-${idx}`} 
          onClick={() => handleImageClick(image)} 
          onError={(e) => e.target.src = ""} 
        />
      ))
    ) : (
      <p className="no-content no-image">画像はありません。</p>
    )}
  </div>
);

export default TabImages;