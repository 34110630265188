import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rss, Flame } from 'lucide-react';

const TopicCard = ({ item, onClick }) => {
  const { t } = useTranslation();

  const renderAttentionIcons = (attention) => {
    const icons = [];
    for (let i = 0; i < attention; i++) {
      icons.push(<Flame key={i} size={12} color="#FF6B6B" />);
    }
    return icons;
  };

  return (
    <div className="topic-card" onClick={() => onClick(item)}>
      <div className="topic-card-content">
        <div className="topic-card-thumbnail">
          {item.news_image_url ? (
            <img src={item.news_image_url} alt={item.title} />
          ) : (
            <Rss size={20} />
          )}
        </div>
        <div className="topic-card-text">
          <p className="topic-card-title">{item.title}</p>
        </div>
      </div>
      <div className="topic-card-footer">
        <div className="topic-card-attention">
          {item.attention > 0 && renderAttentionIcons(item.attention)}
        </div>
        {item.release_date && (
          <p className="topic-card-date">{item.release_date}</p>
        )}
      </div>
    </div>
  );
};

export default TopicCard;