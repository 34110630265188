import { fetchComments } from './api';

export const getComments = async (itemId, setCommentsLoading, setSelectedItem, setCommentsFetched) => {
  try {
    setCommentsLoading(true);
    const data = await fetchComments(itemId);
    setSelectedItem((prevItem) => ({ ...prevItem, comments: data }));
    setCommentsFetched(true);
  } catch (error) {
    console.error('Error fetching comments:', error);
  } finally {
    setCommentsLoading(false);
  }
};

export const addComment = (selectedItem, setSelectedItem, newComment) => {
  setSelectedItem((prevItem) => ({
    ...prevItem,
    comments: [...prevItem.comments, newComment]
  }));
};