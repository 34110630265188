import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { Search, Plus, Minus, X, Rss, ListFilter, ChevronDown } from 'lucide-react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Masonry from 'react-masonry-css';

const TRANSLATABLE_TAG_TYPES = ['area', 'style'];

const getDisplayName = (tag, t) => {
  const { type, name } = tag;

  if (!TRANSLATABLE_TAG_TYPES.includes(type)) {
    return name;
  }

  const translationKey = `tags.${type}.${name}`;
  const translatedName = t(translationKey, name);

  if (translatedName !== name && !TRANSLATABLE_TAG_TYPES.includes(type)) {
    console.warn(`Unexpected translation key exists for non-translatable tag type: ${translationKey}`);
  }

  return translatedName;
};

const TagItem = React.memo(({ item, onClick, isSelected, isExcluded, size, isAnimating }) => {
  const { t } = useTranslation();
  const getFontSize = (size) => {
    const baseFontSize = 0.5; //実質0.6が最小になるはず
    const sizeIncrement = 0.1;
    return `${baseFontSize + (size - 1) * sizeIncrement}em`;
  };

  const displayName = getDisplayName(item, t);

  return (
    <span
      className={`tag-item ${item.type}-tag ${isSelected ? 'selected' : ''} ${isExcluded ? 'excluded' : ''} ${isAnimating ? 'animating' : ''}`}
      style={{
        fontSize: item.type === 'area' || item.type === 'style' ? '0.8em' : getFontSize(size),
        padding: item.type === 'area' || item.type === 'style' ? '4px 16px' : `${4 + size * 2}px ${8 + size * 2}px`,
      }}
      onClick={() => onClick(item.name, item.type, item.id)}
    >
      {displayName}
      {item.is_news && <Rss size={8} style={{ marginLeft: '4px', verticalAlign: 'middle' }} />}
    </span>
  );
});

const AreaStyleCarousel = React.memo(({ areaTags, styleTags, selectedAreas, selectedStyles, excludedAreas, excludedStyles, handleItemClick, visibleTagIds, animatingTags, searchTerm }) => {
  const { t } = useTranslation();

  const sortedTags = useMemo(() => {
    const allTags = [...areaTags, ...styleTags].filter(item => 
      visibleTagIds.has(item.id) ||
      visibleTagIds.has(item.name) ||
      selectedAreas.includes(item.name) ||
      selectedStyles.includes(item.name) ||
      excludedAreas.includes(item.name) ||
      excludedStyles.includes(item.name)
    );

    return allTags.sort((a, b) => {
      const aSelected = selectedAreas.includes(a.name) || selectedStyles.includes(a.name);
      const bSelected = selectedAreas.includes(b.name) || selectedStyles.includes(b.name);
      const aExcluded = excludedAreas.includes(a.name) || excludedStyles.includes(a.name);
      const bExcluded = excludedAreas.includes(b.name) || excludedStyles.includes(b.name);

      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      if (aExcluded && !bExcluded) return -1;
      if (!aExcluded && bExcluded) return 1;
      if (a.type === 'area' && b.type === 'style') return -1;
      if (a.type === 'style' && b.type === 'area') return 1;
      return 0;
    });
  }, [areaTags, styleTags, selectedAreas, selectedStyles, excludedAreas, excludedStyles, visibleTagIds]);

  const filteredTags = useMemo(() => {
    return sortedTags.filter(tag => {
      const displayName = getDisplayName(tag, t);
      return displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
             tag.name.toLowerCase().includes(searchTerm.toLowerCase());
    });
  }, [sortedTags, searchTerm, t]);

  return (
    <div className="area-style-carousel">
      <div className="area-style-tags-container">
        {filteredTags.map((item) => (
          <TagItem
            key={item.name}
            item={item}
            onClick={handleItemClick}
            isSelected={
              (item.type === 'area' && selectedAreas.includes(item.name)) ||
              (item.type === 'style' && selectedStyles.includes(item.name))
            }
            isExcluded={
              (item.type === 'area' && excludedAreas.includes(item.name)) ||
              (item.type === 'style' && excludedStyles.includes(item.name))
            }
            isAnimating={animatingTags.has(item.name)}
          />
        ))}
      </div>
    </div>
  );
});

const SearchButton = ({ tagsData, sensitiveTagsData, isTagsLoading, showInfoBar, clearBoardInfo, isSensitive, isModalOpen, setIsModalOpen }) => {
  const [selectedTags, setSelectedTags] = useState([]);
  const [excludedTags, setExcludedTags] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [excludedLocations, setExcludedLocations] = useState([]);
  const [selectedAreas, setSelectedAreas] = useState([]);
  const [excludedAreas, setExcludedAreas] = useState([]);
  const [selectedStyles, setSelectedStyles] = useState([]);
  const [excludedStyles, setExcludedStyles] = useState([]);
  const [additionalTags, setAdditionalTags] = useState([]);
  const [activeTags, setActiveTags] = useState([]);
  const [visibleTagIds, setVisibleTagIds] = useState(new Set());
  const [selectionMode, setSelectionMode] = useState('include');
  const [currentTags, setCurrentTags] = useState([]);
  const [validCombinations, setValidCombinations] = useState([]);
  const [animatingTags, setAnimatingTags] = useState(new Set());
  const [processingTag, setProcessingTag] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [filterType, setFilterType] = useState('ALL');

  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const [displayedTags, setDisplayedTags] = useState([]);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const loadMoreRef = useRef(null);

  const TAGS_PER_LOAD = 50; // 一度に読み込むタグの数を50に増やす

  const breakpointColumnsObj = {
    default: 5,
    1000: 4,
    700: 3,
    370: 2,
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const urlTags = params.get('tag')?.split(',').filter(Boolean) || [];
    const urlExcludedTags = params.get('exclude_tag')?.split(',').filter(Boolean) || [];
    const urlLocations = params.get('loc')?.split(',').filter(Boolean) || [];
    const urlExcludedLocations = params.get('exclude_loc')?.split(',').filter(Boolean) || [];
    const urlAreas = params.get('area')?.split(',').filter(Boolean) || [];
    const urlExcludedAreas = params.get('exclude_area')?.split(',').filter(Boolean) || [];
    const urlStyles = params.get('style')?.split(',').filter(Boolean) || [];
    const urlExcludedStyles = params.get('exclude_style')?.split(',').filter(Boolean) || [];

    setSelectedTags(urlTags);
    setExcludedTags(urlExcludedTags);
    setSelectedLocations(urlLocations);
    setExcludedLocations(urlExcludedLocations);
    setSelectedAreas(urlAreas);
    setExcludedAreas(urlExcludedAreas);
    setSelectedStyles(urlStyles);
    setExcludedStyles(urlExcludedStyles);

    const tagsToUse = isSensitive ? sensitiveTagsData.tags : tagsData.tags;
    setCurrentTags(tagsToUse);

    const validCombinationsToUse = isSensitive ? sensitiveTagsData.valid_combinations : tagsData.valid_combinations;
    setValidCombinations(validCombinationsToUse || []);

    const existingTagNames = tagsToUse.map((tag) => tag.name);
    const additionalItems = [
      ...urlTags,
      ...urlExcludedTags,
      ...urlLocations,
      ...urlExcludedLocations,
      ...urlAreas,
      ...urlExcludedAreas,
      ...urlStyles,
      ...urlExcludedStyles,
    ]
      .filter((item) => !existingTagNames.includes(item))
      .map((item) => ({
        id: `additional-${item}`,
        name: item,
        type: getTagType(item, urlLocations, urlExcludedLocations, urlAreas, urlExcludedAreas, urlStyles, urlExcludedStyles),
        size: 1,
      }));

    setAdditionalTags(additionalItems);
    setAllTags([...tagsToUse, ...additionalItems]);

    // Update validCombinations to include additional tags
    const updatedValidCombinations = [...validCombinationsToUse];
    additionalItems.forEach(item => {
      updatedValidCombinations.push([item.id]);
    });
    setValidCombinations(updatedValidCombinations);
  }, [location.search, tagsData, sensitiveTagsData, isSensitive]);

  const getTagType = useCallback((item, locations, excludedLocations, areas, excludedAreas, styles, excludedStyles) => {
    if (locations.includes(item) || excludedLocations.includes(item)) return 'location';
    if (areas.includes(item) || excludedAreas.includes(item)) return 'area';
    if (styles.includes(item) || excludedStyles.includes(item)) return 'style';
    return 'tag';
  }, []);

  const areaTags = useMemo(() => {
    return currentTags.filter(tag => tag.type === 'area');
  }, [currentTags]);

  const styleTags = useMemo(() => {
    return currentTags.filter(tag => tag.type === 'style');
  }, [currentTags]);

  const otherTags = useMemo(() => {
    return currentTags.filter(tag => tag.type !== 'area' && tag.type !== 'style');
  }, [currentTags]);

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'unset';
      setSelectionMode('include');
    }
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isModalOpen]);

  useEffect(() => {
    const allSelectedItems = [...selectedTags, ...selectedLocations, ...selectedAreas, ...selectedStyles];
    const allExcludedItems = [...excludedTags, ...excludedLocations, ...excludedAreas, ...excludedStyles];

    if (allSelectedItems.length === 0 && allExcludedItems.length === 0) {
      setActiveTags(allTags.map((tag) => tag.id));
    } else {
      const selectedTagIds = allTags
        .filter((tag) => allSelectedItems.includes(tag.name))
        .map((tag) => tag.id);

      let validTagIds = new Set();

      validCombinations.forEach(combination => {
        if (selectedTagIds.every(id => combination.includes(id))) {
          combination.forEach(id => validTagIds.add(id));
        }
      });

      const excludedTagIds = allTags
        .filter((tag) => allExcludedItems.includes(tag.name))
        .map((tag) => tag.id);

      setActiveTags([...validTagIds].filter(id => !excludedTagIds.includes(id)));
    }
  }, [selectedTags, selectedLocations, selectedAreas, selectedStyles, excludedTags, excludedLocations, excludedAreas, excludedStyles, allTags, validCombinations]);

  useEffect(() => {
    if (isModalOpen) {
      const newVisibleTagIds = new Set(activeTags);
      [...selectedTags, ...excludedTags, ...selectedLocations, ...excludedLocations, ...selectedAreas, ...excludedAreas, ...selectedStyles, ...excludedStyles, ...additionalTags.map(tag => tag.id)].forEach((tag) => newVisibleTagIds.add(tag));
      setVisibleTagIds(newVisibleTagIds);
    }
  }, [isModalOpen, activeTags, selectedTags, excludedTags, selectedLocations, excludedLocations, selectedAreas, excludedAreas, selectedStyles, excludedStyles, additionalTags]);

  const handleItemClick = useCallback((name, type, id) => {
    setAnimatingTags(new Set([...animatingTags, name]));
    setProcessingTag(name);

    const getSelectedArray = (type) => {
      switch (type) {
        case 'location': return selectedLocations;
        case 'area': return selectedAreas;
        case 'style': return selectedStyles;
        default: return selectedTags;
      }
    };

    const getExcludedArray = (type) => {
      switch (type) {
        case 'location': return excludedLocations;
        case 'area': return excludedAreas;
        case 'style': return excludedStyles;
        default: return excludedTags;
      }
    };

    const setSelected = (type) => {
      switch (type) {
        case 'location': return setSelectedLocations;
        case 'area': return setSelectedAreas;
        case 'style': return setSelectedStyles;
        default: return setSelectedTags;
      }
    };

    const setExcluded = (type) => {
      switch (type) {
        case 'location': return setExcludedLocations;
        case 'area': return setExcludedAreas;
        case 'style': return setExcludedStyles;
        default: return setExcludedTags;
      }
    };

    const selectedArray = getSelectedArray(type);
    const excludedArray = getExcludedArray(type);
    const setSelectedFunc = setSelected(type);
    const setExcludedFunc = setExcluded(type);

    if (selectedArray.includes(name) || excludedArray.includes(name)) {
      setSelectedFunc(selectedArray.filter((item) => item !== name));
      setExcludedFunc(excludedArray.filter((item) => item !== name));
    } else {
      if (selectionMode === 'include') {
        setSelectedFunc([...selectedArray, name]);
      } else {
        setExcludedFunc([...excludedArray, name]);
      }
    }

    // After updating the selection state, recalculate visibleTagIds
    const newVisibleTagIds = new Set(visibleTagIds);
    if (selectedArray.includes(name) || excludedArray.includes(name)) {
      newVisibleTagIds.delete(id || name);
    } else {
      newVisibleTagIds.add(id || name);
    }
    setVisibleTagIds(newVisibleTagIds);

    setProcessingTag(null);
  }, [selectedTags, excludedTags, selectedLocations, excludedLocations, selectedAreas, excludedAreas, selectedStyles, excludedStyles, selectionMode, visibleTagIds, animatingTags]);

  useEffect(() => {
    if (processingTag === null && animatingTags.size > 0) {
      setAnimatingTags(new Set());
    }
  }, [processingTag, animatingTags]);

  const toggleSelectionMode = useCallback(() => {
    setSelectionMode((prevMode) => (prevMode === 'include' ? 'exclude' : 'include'));
  }, []);

  const areParamsEqual = useCallback((currentParams, newParams) => {
    const currentEntries = Object.entries(currentParams).sort();
    const newEntries = Object.entries(newParams).sort();
    return JSON.stringify(currentEntries) === JSON.stringify(newEntries);
  }, []);

  const handleSearch = useCallback(() => {
    setIsModalOpen(false);
    const currentParams = Object.fromEntries(new URLSearchParams(location.search));
    const newParams = {};
  
    const addSortedParam = (key, array) => {
      if (array.length > 0) {
        newParams[key] = array.sort().join(',');
      }
    };
  
    addSortedParam('tag', selectedTags);
    addSortedParam('exclude_tag', excludedTags);
    addSortedParam('loc', selectedLocations);
    addSortedParam('exclude_loc', excludedLocations);
    addSortedParam('area', selectedAreas);
    addSortedParam('exclude_area', excludedAreas);
    addSortedParam('style', selectedStyles);
    addSortedParam('exclude_style', excludedStyles);
  
    if (isSensitive) {
      newParams['is_sensitive'] = 'true';
    }
  
    // 何も選択されていない場合、ch=discover を追加
    if (Object.keys(newParams).length === 0 || (Object.keys(newParams).length === 1 && newParams['is_sensitive'])) {
      newParams['ch'] = 'discover';
    }
  
    if (!areParamsEqual(currentParams, newParams)) {
      clearBoardInfo();
    }
  
    const params = new URLSearchParams(newParams);
    navigate(`${location.pathname}?${params.toString()}`);
  }, [selectedTags, excludedTags, selectedLocations, excludedLocations, selectedAreas, excludedAreas, selectedStyles, excludedStyles, isSensitive, location.pathname, clearBoardInfo, navigate, areParamsEqual]);
  
  const handleClearAll = useCallback(() => {
    setSelectedTags([]);
    setExcludedTags([]);
    setSelectedLocations([]);
    setExcludedLocations([]);
    setSelectedAreas([]);
    setExcludedAreas([]);
    setSelectedStyles([]);
    setExcludedStyles([]);
    setSearchTerm('');
  }, []);

  const isClearButtonDisabled = useMemo(() => {
    return (
      selectedTags.length === 0 &&
      excludedTags.length === 0 &&
      selectedLocations.length === 0 &&
      excludedLocations.length === 0 &&
      selectedAreas.length === 0 &&
      excludedAreas.length === 0 &&
      selectedStyles.length === 0 &&
      excludedStyles.length === 0
    );
  }, [selectedTags, excludedTags, selectedLocations, excludedLocations, selectedAreas, excludedAreas, selectedStyles, excludedStyles]);

  const calculateTagSizes = useCallback((tags, validCombinations, visibleTagIds) => {
    const tagCounts = {};
    const visibleTagIdSet = new Set(visibleTagIds);
    const isAllVisible = visibleTagIdSet.size === 0 || visibleTagIdSet.size === tags.length;

    const combinationsToUse = isAllVisible ? validCombinations : validCombinations.filter(combo => 
      combo.every(tagId => visibleTagIdSet.has(tagId))
    );

    tags.forEach(tag => {
      const tagId = tag.id || tag.name;
      if (isAllVisible || visibleTagIdSet.has(tagId)) {
        tagCounts[tagId] = combinationsToUse.filter(combo => combo.includes(tagId)).length;
      }
    });

    const getSize = (count) => {
      if (count <= 3) return 1;
      if (count <= 10) return 2;
      if (count <= 30) return 3;
      if (count <= 100) return 4;
      return 5;
    };

    return Object.fromEntries(
      Object.entries(tagCounts).map(([tagId, count]) => [tagId, { size: getSize(count), count }])
    );
  }, []);

  const sortedVisibleTags = useMemo(() => {
    const selectedAllTags = [...selectedTags, ...selectedLocations, ...selectedAreas, ...selectedStyles];
    const excludedAllTags = [...excludedTags, ...excludedLocations, ...excludedAreas, ...excludedStyles];

    const visibleTags = allTags.filter(
      (item) =>
        visibleTagIds.size === 0 ||
        visibleTagIds.has(item.id) ||
        visibleTagIds.has(item.name) ||
        selectedAllTags.includes(item.name) ||
        excludedAllTags.includes(item.name)
    );

    const tagSizes = calculateTagSizes(allTags, validCombinations, visibleTagIds);

    return visibleTags.sort((a, b) => {
      const aSelected = selectedAllTags.includes(a.name);
      const bSelected = selectedAllTags.includes(b.name);
      const aExcluded = excludedAllTags.includes(a.name);
      const bExcluded = excludedAllTags.includes(b.name);
      const aCount = tagSizes[a.id || a.name]?.count || 0;
      const bCount = tagSizes[b.id || b.name]?.count || 0;

      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      if (aExcluded && !bExcluded) return -1;
      if (!aExcluded && bExcluded) return 1;
      return bCount - aCount;
    });
  }, [allTags, visibleTagIds, selectedTags, excludedTags, selectedLocations, excludedLocations, selectedAreas, excludedAreas, selectedStyles, excludedStyles, validCombinations, calculateTagSizes]);

  const toggleFilterType = useCallback(() => {
    setFilterType((prevType) => {
      switch (prevType) {
        case 'ALL':
          return 'LOCATION';
        case 'LOCATION':
          return 'CATEGORY';
        case 'CATEGORY':
          return 'BRAND';
        case 'BRAND':
          return 'ALL';
        default:
          return 'ALL';
      }
    });
  }, []);

  const filteredVisibleTags = useMemo(() => {
    const selectedAllTags = [...selectedTags, ...selectedLocations, ...selectedAreas, ...selectedStyles];
    const excludedAllTags = [...excludedTags, ...excludedLocations, ...excludedAreas, ...excludedStyles];
    const currentlySelectedOrExcluded = new Set([...selectedAllTags, ...excludedAllTags]);
  
    return sortedVisibleTags.filter(tag => {
      const displayName = getDisplayName(tag, t);
      const matchesSearch = displayName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                            tag.name.toLowerCase().includes(searchTerm.toLowerCase());
      const isCurrentlySelected = currentlySelectedOrExcluded.has(tag.name);
      const hasRequiredLevel = tag.level && (
        filterType === 'BRAND' ? tag.level >= 1 : tag.level >= 2
      );
  
      let matchesFilter = true;
      if (!isCurrentlySelected) {
        switch (filterType) {
          case 'LOCATION':
            matchesFilter = tag.type === 'location';
            break;
          case 'CATEGORY':
            matchesFilter = tag.type === 'sub_category' || tag.type === 'content';
            break;
          case 'BRAND':
            matchesFilter = tag.type === 'ip';
            break;
          default:
            break;
        }
      }
  
      return (isCurrentlySelected || (matchesFilter && ((searchTerm !== '' && matchesSearch) || (searchTerm === '' && hasRequiredLevel))));
    }).sort((a, b) => {
      const aSelected = currentlySelectedOrExcluded.has(a.name);
      const bSelected = currentlySelectedOrExcluded.has(b.name);
      if (aSelected && !bSelected) return -1;
      if (!aSelected && bSelected) return 1;
      return 0;
    });
  }, [sortedVisibleTags, searchTerm, t, selectedTags, selectedLocations, selectedAreas, selectedStyles, excludedTags, excludedLocations, excludedAreas, excludedStyles, filterType]);

  const loadMoreTags = useCallback(() => {
    if (isLoadingMore || !hasMore) return;

    setIsLoadingMore(true);
    setTimeout(() => {
      setDisplayedTags(prevTags => {
        const newTags = filteredVisibleTags.slice(prevTags.length, prevTags.length + TAGS_PER_LOAD);
        const updatedTags = [...prevTags, ...newTags];
        setHasMore(updatedTags.length < filteredVisibleTags.length);
        return updatedTags;
      });
      setIsLoadingMore(false);
    }, 100); // タイムアウトを300msから100msに減らす
  }, [filteredVisibleTags, isLoadingMore, hasMore]);

  useEffect(() => {
    setDisplayedTags(filteredVisibleTags.slice(0, TAGS_PER_LOAD));
    setHasMore(filteredVisibleTags.length > TAGS_PER_LOAD);
  }, [filteredVisibleTags]);

  // タグサイズの計算を最適化
  const tagSizes = useMemo(() => {
    return calculateTagSizes(filteredVisibleTags, validCombinations, visibleTagIds);
  }, [filteredVisibleTags, validCombinations, visibleTagIds, calculateTagSizes]);
  
  useEffect(() => {
    const observer = new IntersectionObserver(
      entries => {
        if (entries[0].isIntersecting) {
          loadMoreTags();
        }
      },
      { threshold: 1 }
    );

    if (loadMoreRef.current) {
      observer.observe(loadMoreRef.current);
    }

    return () => {
      if (loadMoreRef.current) {
        observer.unobserve(loadMoreRef.current);
      }
    };
  }, [loadMoreTags]);


  useEffect(() => {
    if (isModalOpen) {
      setSearchTerm('');
    }
  }, [isModalOpen]);

  const handleSearchInputChange = useCallback((e) => {
    setSearchTerm(e.target.value);
  }, []);

  const handleResetSearchTerm = useCallback(() => {
    setSearchTerm('');
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalOpen(false);
    setSearchTerm('');
  }, [setIsModalOpen]);

  return (
    <>
      <div className="floating-search-button" onClick={() => setIsModalOpen(true)}>
        <Search size={24} />
      </div>
      {isModalOpen && (
        <div className="modal-overlay" onClick={handleCloseModal}>
          <div className="modal-content tag-modal-content" onClick={(e) => e.stopPropagation()}>
            <div className="modal-header">
              <div onClick={toggleSelectionMode} className="toggle-selection-mode">
                {selectionMode === 'include' ? (
                  <>
                    {t('findByTag')}
                    <Plus size={16} />
                  </>
                ) : (
                  <>
                    {t('excludeByTag')}
                    <Minus size={16} />
                  </>
                )}
              </div>
              <div onClick={toggleFilterType} className="filter-type-toggle">
                <ListFilter size={12} />
                <span>{filterType}</span>
              </div>
            </div>
            <div className="search-input-container">
              <input
                type="text"
                value={searchTerm}
                onChange={handleSearchInputChange}
                placeholder={t('searchTags')}
                className="search-input"
              />
              {searchTerm && (
                <button onClick={handleResetSearchTerm} className="reset-search-button">
                  <X size={16} />
                </button>
              )}
            </div>
            {isTagsLoading ? (
              <div className="loading-spinner"></div>
            ) : (
              <>
                <AreaStyleCarousel
                  areaTags={areaTags}
                  styleTags={styleTags}
                  selectedAreas={selectedAreas}
                  selectedStyles={selectedStyles}
                  excludedAreas={excludedAreas}
                  excludedStyles={excludedStyles}
                  handleItemClick={handleItemClick}
                  visibleTagIds={visibleTagIds}
                  animatingTags={animatingTags}
                  searchTerm={searchTerm}
                />
                <div className="tag-list-container">
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="tag-list my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {displayedTags.filter(item => item.type !== 'area' && item.type !== 'style').map((item) => {
                      const tagSizeInfo = tagSizes[item.id || item.name] || { size: 1, count: 0 };
                      return (
                        <div
                          key={item.name}
                          className={`tag-item-wrapper ${
                            visibleTagIds.has(item.id) || visibleTagIds.has(item.name) ? 'fade-in' : 'fade-out'
                          }`}
                        >
                          <TagItem
                            item={item}
                            onClick={handleItemClick}
                            isSelected={
                              (item.type === 'location' && selectedLocations.includes(item.name)) ||
                              (item.type === 'area' && selectedAreas.includes(item.name)) ||
                              (item.type === 'style' && selectedStyles.includes(item.name)) ||
                              selectedTags.includes(item.name)
                            }
                            isExcluded={
                              (item.type === 'location' && excludedLocations.includes(item.name)) ||
                              (item.type === 'area' && excludedAreas.includes(item.name)) ||
                              (item.type === 'style' && excludedStyles.includes(item.name)) ||
                              excludedTags.includes(item.name)
                            }
                            size={tagSizeInfo.size}
                            isAnimating={animatingTags.has(item.name)}
                          />
                        </div>
                      );
                    })}
                  </Masonry>
                  {hasMore && (
                    <div ref={loadMoreRef} className="load-more">
                      {isLoadingMore ? (
                        <div className="loading-spinner"></div>
                      ) : (
                        <ChevronDown size={16} />
                      )}
                    </div>
                  )}
                  <div className='tags-count'>Displayed Tags: {displayedTags.length} / Total Tags: {filteredVisibleTags.length}</div>
                </div>
                <div className="search-actions">
                  <button onClick={handleClearAll} className="clear-all-button" disabled={isClearButtonDisabled}>
                    {t('clearAll')}
                  </button>
                  <button onClick={handleSearch} className="search-button">
                    {t('search')}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(SearchButton);