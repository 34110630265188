import React from 'react';
import { Menu, X } from 'lucide-react';

const Header = ({ toggleDrawer, isDrawerOpen, isSensitive }) => {
  return (
    <header>
      <button className="drawer-toggle" onClick={toggleDrawer}>
        {isDrawerOpen ? <X size={24} /> : <Menu size={24} />}
        {isSensitive && <span>18+</span>}
      </button>
    </header>
  );
};

export default Header;