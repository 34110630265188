import React from 'react';

const StructuredData = ({ urlParams, boardTitle, boardSubtitle, items }) => {
  let structuredData = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": document.title,
    "description": document.querySelector('meta[name="description"]')?.content,
  };

  if (urlParams.tbl) {
    structuredData = {
      ...structuredData,
      "@type": "CollectionPage",
      "about": {
        "@type": "Thing",
        "name": boardTitle,
        "description": boardSubtitle
      }
    };
  } else {
    const keywords = document.querySelector('meta[name="keywords"]')?.content.split(', ');
    structuredData = {
      ...structuredData,
      "@type": "SearchResultsPage",
      "about": keywords?.map(keyword => ({
        "@type": "Thing",
        "name": keyword
      }))
    };
  }

  if (items && items.length > 0) {
    structuredData.mainEntity = {
      "@type": "ItemList",
      "itemListElement": items.slice(0, 10).map((item, index) => ({
        "@type": "ListItem",
        "position": index + 1,
        "item": {
          "@type": "Event",
          "name": item.title,
          "description": item.overview || undefined,
          "startDate": item.next_start_time,
          "endDate": item.last_end_time || undefined,
          "eventAttendanceMode": "https://schema.org/OfflineEventAttendanceMode",
          "eventStatus": "https://schema.org/EventScheduled",
          "location": {
            "@type": "Place",
            "name": item.location || "Event Location",
            "address": {
              "@type": "PostalAddress",
              "addressLocality": item.location || "Event Location"
            }
          },
          "image": item.image || undefined,
          "url": item.sourceLink || undefined,
          "organizer": {
            "@type": "Organization",
            "name": item.organizer || "Event Organizer"
          },
          "offers": item.price ? {
            "@type": "Offer",
            "price": item.price,
            "priceCurrency": "JPY"
          } : undefined
        }
      }))
    };
  }

  return (
    <script type="application/ld+json">
      {JSON.stringify(structuredData)}
    </script>
  );
};

export default StructuredData;