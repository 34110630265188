import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { leaveBoard, fetchInviteUrl } from '../services/api';
import { List, UserX } from 'lucide-react'; // UserXアイコンも追加

const LeaveBoardModal = ({ isOpen, onClose, boardId, onLeaveSuccess }) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showMemberList, setShowMemberList] = useState(false);
  const [memberDatas, setMemberDatas] = useState([]);

  useEffect(() => {
    if (isOpen && boardId) {
      setIsLoading(true);
      setError(null);
      fetchInviteUrl(boardId)
        .then(response => {
          setMemberDatas(response.memberDatas || []);
        })
        .catch(err => {
          setError(err.message || t('fetchMembersError'));
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, boardId, t]);

  const handleLeave = async () => {
    setIsLoading(true);
    setError(null);
    try {
      await leaveBoard(boardId);
      onLeaveSuccess();
      onClose();
    } catch (err) {
      setError(err.message || t('leaveBoardError'));
    } finally {
      setIsLoading(false);
    }
  };

  const toggleView = () => {
    setShowMemberList(!showMemberList);
  };

  const renderMemberName = (member) => {
    if (member.member_name && member.member_name.trim() !== '') {
      return member.member_name;
    } else {
      return <span className="unset-name">{t('unsetName')}</span>;
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{showMemberList ? t('memberList') : t('leaveBoardConfirmation')}</h2>
          <div className="modal-actions">
            {showMemberList ? (
              <UserX
                size={20}
                onClick={toggleView}
                aria-label={t('showLeaveConfirmation')}
              />
            ) : (
              <List
                size={20}
                onClick={toggleView}
                aria-label={t('showMemberList')}
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : showMemberList ? (
          <>
            {memberDatas.length > 0 ? (
              <ul className="member-list">
                {memberDatas.map((member) => (
                  <li key={member.member_code}>
                    {renderMemberName(member)}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="no-members-message">{t('noMembersYet')}</p>
            )}
          </>
        ) : (
          <>
            <p className='modal-description'>{t('leaveBoardWarning')}</p>
            <div className="modal-buttons">
              <button onClick={onClose} disabled={isLoading}>
                {t('cancel')}
              </button>
              <button onClick={handleLeave} disabled={isLoading}>
                {isLoading ? t('processing') : t('leave')}
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default LeaveBoardModal;