import React from 'react';
import { ClipboardPlus, ClipboardX, Loader } from 'lucide-react';

const FollowButton = ({ isFollow, onFollowAction, isLoading }) => {
  return (
    <div className="follow-button-container">
      <div 
        className={isFollow ? "unfollow-button" : "follow-button"}
        onClick={onFollowAction}
        aria-label={isFollow ? "Unfollow" : "Follow"}
      >
        {isLoading ? (
          <Loader size={20} className="follow-loader" />
        ) : isFollow ? (
          <ClipboardX size={20} />
        ) : (
          <ClipboardPlus size={20} />
        )}
      </div>
    </div>
  );
};

export default FollowButton;