import React from 'react';
import { useTheme } from '@aws-amplify/ui-react';
import logo from '../assets/logo.png'; // ロゴのパスを適切に調整してください

export const amplifyComponents = {
  Header() {
    const { tokens } = useTheme();
    return (
      <div></div>
      // <div style={{
      //   padding: '0px',
      //   backgroundColor: tokens.colors.brand.primary[10],
      //   textAlign: 'center',
      // }}>
      //   <img
      //     src={logo}
      //     alt="Logo"
      //     style={{
      //       maxWidth: '26px',
      //       marginTop: '0px',
      //       marginBottom: '5px',
      //     }}
      //   />
      //   {/* <h4 style={{ color: tokens.colors.brand.primary[80], marginTop: '0px' , marginBottom: '10px' }}>poppin.link</h4> */}
      // </div>
    );
  },
};