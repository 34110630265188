import React, { useRef, useEffect } from 'react';
import QRCode from 'qrcode';
import { useTranslation } from 'react-i18next';
import { Link, QrCode, Download, Share2 } from 'lucide-react';

const ShareModal = ({ onClose, boardTitle, boardSubtitle, backgroundImageUrl, avatarUrl }) => {
  const { t } = useTranslation();
  const shareUrl = window.location.href;
  const canvasRef = useRef(null);

  const truncateUrl = (url) => {
    if (url.length > 40) {
      return url.substring(0, 40) + '...';
    }
    return url;
  };

  useEffect(() => {
    if (canvasRef.current) {
      generateShareImage();
    }
  }, [boardTitle, boardSubtitle, backgroundImageUrl, avatarUrl]);

  const generateShareImage = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    
    // キャンバスのサイズを設定
    canvas.width = 1200;
    canvas.height = 1200;

    try {
      if (backgroundImageUrl) {
        await drawBackgroundImage(ctx, backgroundImageUrl);
      } else {
        drawWhiteBackground(ctx);
      }
    } catch (error) {
      console.error('Error loading background image:', error);
      drawWhiteBackground(ctx);
    }

    drawMiddleLayer();
  };

  const drawBackgroundImage = (ctx, url) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.crossOrigin = "anonymous";
      img.onload = () => {
        ctx.drawImage(img, 0, 0, ctx.canvas.width, ctx.canvas.height);
        resolve();
      };
      img.onerror = reject;
      img.src = url;
    });
  };

  const drawWhiteBackground = (ctx) => {
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, ctx.canvas.width, ctx.canvas.height);
  };

  const drawMiddleLayer = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // 半透明の白い背景を追加
    ctx.fillStyle = 'rgba(255, 255, 255, 0.4)';
    ctx.fillRect(0, 0, canvas.width, canvas.height);

    // 中間層の画像を描画
    const middleLayerImg = new Image();
    middleLayerImg.onload = () => {
      ctx.drawImage(middleLayerImg, 0, 0, canvas.width, canvas.height);
      drawContent();
    };
    middleLayerImg.onerror = () => {
      console.error('Middle layer image failed to load');
      drawContent();
    };
    middleLayerImg.src = '../images/other/share-middle-layer.png';
  };

  const drawTextWithLetterSpacing = (ctx, text, x, y, letterSpacing) => {
    let currentX = x;
    for (let i = 0; i < text.length; i++) {
      ctx.fillText(text[i], currentX, y);
      currentX += ctx.measureText(text[i]).width + letterSpacing;
    }
  };

  const drawContent = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // タイトルを描画
    ctx.fillStyle = 'rgba(0, 0, 0, 0.9)';
    ctx.font = 'bold 60px Arial';
    ctx.textAlign = 'left';
    const titleX = (canvas.width - ctx.measureText(boardTitle).width - (boardTitle.length - 1) * 5) / 2;
    drawTextWithLetterSpacing(ctx, boardTitle, titleX, 220, 5);

    const avatarSize = 120;
    const avatarY = 270;
    const cornerRadius = 10;
    const avatarX = (canvas.width - avatarSize) / 2;

    if (avatarUrl) {
      // サブタイトルを描画 (アバターがある場合)
      ctx.font = '40px Arial';
      const subtitleX = (canvas.width - ctx.measureText(boardSubtitle).width - (boardSubtitle.length - 1) * 5) / 2;
      drawTextWithLetterSpacing(ctx, boardSubtitle, subtitleX, 450, 5);

      // アバターを描画
      const avatarImg = new Image();
      avatarImg.crossOrigin = "anonymous";
      avatarImg.onload = () => {
        // アバターを角丸の四角形に切り取って描画
        ctx.save();
        ctx.beginPath();
        ctx.moveTo(avatarX + cornerRadius, avatarY);
        ctx.lineTo(avatarX + avatarSize - cornerRadius, avatarY);
        ctx.quadraticCurveTo(avatarX + avatarSize, avatarY, avatarX + avatarSize, avatarY + cornerRadius);
        ctx.lineTo(avatarX + avatarSize, avatarY + avatarSize - cornerRadius);
        ctx.quadraticCurveTo(avatarX + avatarSize, avatarY + avatarSize, avatarX + avatarSize - cornerRadius, avatarY + avatarSize);
        ctx.lineTo(avatarX + cornerRadius, avatarY + avatarSize);
        ctx.quadraticCurveTo(avatarX, avatarY + avatarSize, avatarX, avatarY + avatarSize - cornerRadius);
        ctx.lineTo(avatarX, avatarY + cornerRadius);
        ctx.quadraticCurveTo(avatarX, avatarY, avatarX + cornerRadius, avatarY);
        ctx.closePath();
        ctx.clip();
        ctx.drawImage(avatarImg, avatarX, avatarY, avatarSize, avatarSize);
        ctx.restore();

        // QRコードとURLを描画
        drawQRCodeAndURL();
      };
      avatarImg.onerror = () => {
        console.error('Avatar image failed to load');
        // アバターがロードできない場合でもQRコードを描画
        drawQRCodeAndURL();
      };
      avatarImg.src = avatarUrl;
    } else {
      // サブタイトルを描画 (アバターがない場合)
      ctx.font = '40px Arial';
      const subtitleX = (canvas.width - ctx.measureText(boardSubtitle).width - (boardSubtitle.length - 1) * 5) / 2;
      drawTextWithLetterSpacing(ctx, boardSubtitle, subtitleX, avatarY + avatarSize / 2, 5);

      // アバターがない場合はQRコードを描画
      drawQRCodeAndURL();
    }
  };

  const drawQRCodeAndURL = async () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');

    // QRコードを生成して描画
    const qrCodeDataUrl = await QRCode.toDataURL(shareUrl, {
      width: 560,
      margin: 1,
      color: {
        dark: '#000000ee',  // QRコードの色
        light: '#FFFFFF00'  // 背景色
      }
    });
    const qrImage = new Image();
    qrImage.onload = () => {
      ctx.drawImage(qrImage, 664, 546, 460, 460);
      
      // // 切り詰めたURLを描画
      // ctx.font = '32px Arial';
      // ctx.fillStyle = 'rgba(0, 0, 0, 0.4)';
      // ctx.fillText(truncateUrl(shareUrl), canvas.width / 2, 1100);
    };
    qrImage.src = qrCodeDataUrl;
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(shareUrl);
    alert(t('linkCopied'));
  };

  const handleImageAction = async () => {
    const canvas = canvasRef.current;
    const imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
    
    if (navigator.share && navigator.canShare && navigator.canShare({ files: [new File([imageBlob], 'share-image.png', { type: 'image/png' })] })) {
      // モバイルデバイスでWeb Share APIが利用可能な場合
      try {
        await navigator.share({
          files: [new File([imageBlob], 'share-image.png', { type: 'image/png' })],
          title: boardTitle,
          text: `${boardTitle} - ${boardSubtitle}`
        });
        console.log(t('shareSuccess'));
      } catch (err) {
        if (err.name !== 'AbortError') {
          console.error('Share failed:', err);
          alert(t('shareFailed'));
        }
        // AbortError（ユーザーによるキャンセル）の場合は何もしない
      }
    } else if (navigator.clipboard && navigator.clipboard.write) {
      // クリップボードAPIが利用可能な場合（主にPC）
      try {
        await navigator.clipboard.write([
          new ClipboardItem({
            'image/png': imageBlob
          })
        ]);
        alert(t('imageCopied'));
      } catch (err) {
        console.error('Failed to copy image:', err);
        fallbackImageCopy(imageBlob);
      }
    } else {
      // どちらも利用できない場合はダウンロードにフォールバック
      fallbackImageCopy(imageBlob);
    }
  };

  const fallbackImageCopy = (imageBlob) => {
    const url = URL.createObjectURL(imageBlob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'share-image.png';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
    alert(t('imageDownloaded'));
  };

  const handleShare = async () => {
    if (navigator.share) {
      const canvas = canvasRef.current;
      const imageBlob = await new Promise(resolve => canvas.toBlob(resolve, 'image/png'));
      const imageFile = new File([imageBlob], 'share-image.png', { type: 'image/png' });

      const shareText = `${boardTitle} - ${boardSubtitle}\n${shareUrl}`;

      navigator.share({
        title: "poppin.link",
        text: shareText,
        files: [imageFile]
      }).then(() => {
        console.log(t('shareSuccess'));
      }).catch(console.error);
    } else {
      alert(t('browserNotSupported'));
    }
  };

  return (
    <div className="share-modal-overlay" onClick={onClose}>
      <div className="share-modal-content" onClick={(e) => e.stopPropagation()}>
        <canvas className="share-image" ref={canvasRef} style={{ width: '100%', maxWidth: ' 1200px' }} />
        <div className="share-options">
          <button onClick={handleCopyLink} className="copy-link"><Link size={10} />{t('copyLink')}</button>
          <button onClick={handleImageAction} className="save-image">
            {navigator.share ? <QrCode size={10} /> : <QrCode size={10} />}
            {navigator.share ? t('saveImage') : t('saveImage')}
          </button>
        </div>
        <div className="share-buttons">
          <button onClick={handleShare} className="share-button-general">{t('share')}</button>
        </div>
      </div>
    </div>
  );
};

export default ShareModal;