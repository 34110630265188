import { useState, useEffect, useCallback } from 'react';
import { getCurrentUser, signOut as amplifySignOut, fetchAuthSession } from 'aws-amplify/auth';
import { loginUser } from '../services/api';  // パスを修正

export const useAuth = () => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const fetchUserInfo = useCallback(async () => {
    setIsLoading(true);
    try {
      const currentUser = await getCurrentUser();
      const { tokens } = await fetchAuthSession();
      const token = tokens.idToken.toString();
      
      // APIサーバーにログイン情報を送信
      const loginResponse = await loginUser(token);
      
      setUserInfo({ ...currentUser, ...loginResponse });
    } catch (error) {
      if (error.name === 'UserUnAuthenticatedException') {
        // ユーザーが認証されていない場合は問題ありません
        setUserInfo(null);
      } else {
        console.error('ユーザー情報の取得中にエラーが発生しました', error);
      }
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUserInfo();
  }, [fetchUserInfo]);

  const signOut = async () => {
    try {
      await amplifySignOut();
      setUserInfo(null);
    } catch (error) {
      console.error('サインアウト中にエラーが発生しました', error);
    }
  };

  const updateUserInfo = useCallback((newUserData) => {
    setUserInfo(prevInfo => ({ ...prevInfo, ...newUserData }));
  }, []);

  return { userInfo, setUserInfo: updateUserInfo, isLoading, signOut, refetchUserInfo: fetchUserInfo };
};