import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SquarePen, ImageUp } from 'lucide-react';
import { uploadAvatar } from '../services/api';

const AvatarSelector = ({ currentAvatar, onUpdate, onBack, isLoading }) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewUrl, setPreviewUrl] = useState(currentAvatar);
  const fileInputRef = useRef(null);

  const resizeImage = (file, targetWidth, targetHeight) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');

          // Set canvas size to target dimensions
          canvas.width = targetWidth;
          canvas.height = targetHeight;

          // Calculate scaling and positioning
          const scale = Math.max(targetWidth / img.width, targetHeight / img.height);
          const scaledWidth = img.width * scale;
          const scaledHeight = img.height * scale;
          const offsetX = (targetWidth - scaledWidth) / 2;
          const offsetY = (targetHeight - scaledHeight) / 2;

          // Draw image on canvas with cover-style scaling
          ctx.drawImage(img, offsetX, offsetY, scaledWidth, scaledHeight);

          canvas.toBlob((blob) => {
            resolve(new File([blob], file.name, {
              type: 'image/jpeg',
              lastModified: Date.now()
            }));
          }, 'image/jpeg', 0.7);
        };
        img.src = e.target.result;
      };
      reader.readAsDataURL(file);
    });
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      const resizedFile = await resizeImage(file, 600, 600);
      setSelectedFile(resizedFile);
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreviewUrl(reader.result);
      };
      reader.readAsDataURL(resizedFile);
    }
  };

  const handleUpload = async () => {
    if (selectedFile) {
      try {
        onUpdate(null); // Start loading state
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64data = reader.result;
          const response = await uploadAvatar(base64data);
          onUpdate(response.avatarUrl);
        };
        reader.readAsDataURL(selectedFile);
      } catch (error) {
        console.error('Avatar upload failed:', error);
        onUpdate(null); // End loading state on error
      }
    }
  };

  const triggerFileInput = () => {
    fileInputRef.current.click();
  };

  const handleImageError = () => {
    console.log('Image failed to load:', previewUrl);
    setPreviewUrl(null);
  };

  return (
    <>
      <div className="modal-header">
        <h2>{t('updateAvatar')}</h2>
        <button 
          className="avatar-button"
          onClick={onBack}
        >
          <SquarePen size={20} />
        </button>
      </div>
      <p className="modal-description">{t('selectNewAvatarDescription')}</p>
      <div className="avatar-preview" onClick={triggerFileInput}>
        {previewUrl ? (
          <img 
            src={previewUrl} 
            alt="Avatar preview" 
            onError={handleImageError}
          />
        ) : (
          <div className="no-avatar">
            <ImageUp size={40} />
          </div>
        )}
      </div>
      <input
        type="file"
        ref={fileInputRef}
        onChange={handleFileChange}
        accept="image/*"
        style={{ display: 'none' }}
      />
      <button
        className="update-button"
        onClick={handleUpload}
        disabled={isLoading || !selectedFile}
      >
        {isLoading ? t('updating') : t('update')}
      </button>
    </>
  );
};

export default AvatarSelector;