import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Trash2, Image } from 'lucide-react';
import BackgroundSelector from './BackgroundSelector';

const BoardEditModal = ({ isOpen, onClose, onUpdate, onDelete, currentBoardData }) => {
  const { t } = useTranslation();
  const [boardData, setBoardData] = useState({ name: '', description: '', background: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showBackgroundSelector, setShowBackgroundSelector] = useState(false);

  useEffect(() => {
    if (currentBoardData) {
      setBoardData({ 
        name: currentBoardData.name || '', 
        description: currentBoardData.description || '',
        background: currentBoardData.background || '' 
      });
    }
  }, [currentBoardData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBoardData(prevData => ({ ...prevData, [name]: value }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (boardData.name.length === 0) {
      setError(t('enterTableName'));
      return;
    }
    setIsLoading(true);
    try {
      await onUpdate(boardData);
      onClose();
    } catch (error) {
      console.error(t('failedToUpdateBoardData'), error);
      setError(t('failedToUpdateTableName'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm(t('confirmDeleteTable'))) {
      setIsLoading(true);
      try {
        await onDelete();
        onClose();
      } catch (error) {
        console.error(t('failedToDeleteBoard'), error);
        setError(t('failedToDeleteTable'));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  const handleBackgroundSelect = (backgroundId) => {
    setBoardData(prevData => ({ ...prevData, background: backgroundId }));
  };

  const handleBackgroundUpdate = async () => {
    setIsLoading(true);
    try {
      await onUpdate(boardData);
      setShowBackgroundSelector(false);
    } catch (error) {
      console.error(t('failedToUpdateBackground'), error);
      setError(t('failedToUpdateBackground'));
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay board-edit-modal" onClick={handleOverlayClick}>
      <div className="modal-content">
        {!showBackgroundSelector ? (
          <>
            <div className="modal-header">
              <h2>{t('changeTableInfo')}</h2>
              <button 
                className="background-button"
                onClick={() => setShowBackgroundSelector(true)}
              >
                <Image size={20} />
              </button>
            </div>
            <p className="modal-description">{t('editBoardInfoDescription')}</p>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="modal-form">
              <input
                id="name"
                name="name"
                value={boardData.name || ''}
                onChange={handleChange}
                disabled={isLoading}
                className="modal-form-input"
                maxLength={15}
                placeholder={t('enterTableNameWithin15Chars')}
              />
              <p className="char-count">{boardData.name.length}/15</p>
              <textarea
                id="description"
                name="description"
                value={boardData.description || ''}
                onChange={handleChange}
                disabled={isLoading}
                className="modal-form-input"
                maxLength={100}
                placeholder={t('enterTableDescriptionWithin100Chars')}
              />
              <p className="char-count">{boardData.description.length}/100</p>
              <button type="submit" disabled={isLoading}>
                {isLoading ? t('updating') : t('update')}
              </button>
            </form>
            <button
              onClick={handleDelete}
              disabled={isLoading}
              className="delete-board-button"
            >
              <Trash2 size={16} />
              {t('deleteThisTable')}
            </button>
          </>
        ) : (
          <BackgroundSelector
            currentBackground={boardData.background}
            onSelect={handleBackgroundSelect}
            onUpdate={handleBackgroundUpdate}
            onBack={() => setShowBackgroundSelector(false)}
            isLoading={isLoading}
          />
        )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>{t('processing')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default BoardEditModal;