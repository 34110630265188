import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { SquarePen } from 'lucide-react';

const EMPTY_BACKGROUND_ID = 'bg_empty';

const backgrounds = [
    { id: EMPTY_BACKGROUND_ID, name: 'None' },
    { id: "bg_00001", name: "Background 1" },
    { id: "bg_00002", name: "Background 2" },
    { id: "bg_00003", name: "Background 3" },
    { id: "bg_00004", name: "Background 4" },
    { id: "bg_00005", name: "Background 5" },
    { id: "bg_00006", name: "Background 6" },
    { id: "bg_00007", name: "Background 7" },
    { id: "bg_00008", name: "Background 8" },
    { id: "bg_00009", name: "Background 9" },
    { id: "bg_00010", name: "Background 10" },
    { id: "bg_00011", name: "Background 11" },
    { id: "bg_00012", name: "Background 12" },
    { id: "bg_00013", name: "Background 13" },
    { id: "bg_00014", name: "Background 14" },
    { id: "bg_00015", name: "Background 15" },
    { id: "bg_00016", name: "Background 16" },
    { id: "bg_01001", name: "Background 1001" },
    { id: "bg_01002", name: "Background 1002" },
    { id: "bg_01003", name: "Background 1003" },
    { id: "bg_01004", name: "Background 1004" },  
    { id: "bg_02001", name: "Background 2001" },
    { id: "bg_02002", name: "Background 2002" },
    { id: "bg_02003", name: "Background 2003" },
    { id: "bg_02004", name: "Background 2004" },  
    { id: "bg_03001", name: "Background 3001" },
    { id: "bg_03002", name: "Background 3002" },
    { id: "bg_03003", name: "Background 3003" },
    { id: "bg_03004", name: "Background 3004" },
    { id: "bg_04001", name: "Background 4001" },
    { id: "bg_04002", name: "Background 4002" },
    { id: "bg_04003", name: "Background 4003" },
    { id: "bg_04004", name: "Background 4004" }
];

const BackgroundSelector = ({ currentBackground, onSelect, onUpdate, onBack, isLoading }) => {
  const { t } = useTranslation();
  const [selectedBackground, setSelectedBackground] = useState(null);


  const handleSelect = (backgroundId) => {
    setSelectedBackground(backgroundId);
    onSelect(backgroundId);
  };

  const handleUpdate = () => {
    if (selectedBackground !== null) {
      onUpdate(selectedBackground);
    }
  };

  const isUpdateDisabled = isLoading || selectedBackground === null;

  // 現在のホストドメインを取得
  const currentHostDomain = window.location.origin;

  return (
    <div className="background-selector board-edit-modal">
      <div className="modal-header">
        <h2>{t('selectBackground')}</h2>
        <button className="back-button" onClick={onBack}>
        <SquarePen size={20} />
        </button>
      </div>
      <div className="background-grid">
        {backgrounds.map((bg) => (
          <div
            key={bg.id}
            className={`background-option ${selectedBackground === bg.id ? 'selected' : ''}`}
            onClick={() => handleSelect(bg.id)}
          >
            {bg.id !== EMPTY_BACKGROUND_ID ? (
              <img 
                src={`${currentHostDomain}/images/background/thumbnails/${bg.id}.jpg`} 
                alt={bg.name} 
              />
            ) : (
              <div className="no-background">{t('noBackground')}</div>
            )}
          </div>
        ))}
      </div>
      <button
        className="update-button"
        onClick={handleUpdate}
        disabled={isUpdateDisabled}
      >
        {isLoading ? t('updating') : t('update')}
      </button>
    </div>
  );
};

export default BackgroundSelector;