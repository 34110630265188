const getPlaceholder = (enText, jpText) => {
  const language = window.location.pathname.startsWith('/en') ? 'en' : 'jp';
  return language === 'en' ? enText : jpText;
};

// ここだけi18nによる多言語化していない（少しややこしいため）

export const amplifyFormFields = {
  signIn: {
    username: {
      placeholder: getPlaceholder('Enter email address', 'メールアドレスを入力'),
    },
    password: {
      placeholder: getPlaceholder('Enter password', 'パスワードを入力'),
    },
  },
  signUp: {
    email: {
      placeholder: getPlaceholder('Enter email address', 'メールアドレスを入力'),
    },
    password: {
      placeholder: getPlaceholder('Enter password', 'パスワードを入力'),
    },
    confirm_password: {
      placeholder: getPlaceholder('Re-enter password', 'パスワードを再入力'),
    },
  },
};