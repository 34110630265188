import { useState, useCallback, useEffect } from 'react';
import { fetchFollowItems, followItem, unfollowItem } from '../services/api';
import { useTranslation } from 'react-i18next';

export const useFollowState = (userInfo, showInfoBar, setShowLogin, updateModalState) => {
    const { t } = useTranslation();
    const [followItems, setFollowItems] = useState([]);
    const [isFollowStateLoaded, setIsFollowStateLoaded] = useState(false);
    const [hasLoadedFollowData, setHasLoadedFollowData] = useState(false);
    const [isFollowLoading, setIsFollowLoading] = useState(false);
    const [followAnimationType, setFollowAnimationType] = useState(null);

    const fetchInitialFollowState = useCallback(async () => {
        if (userInfo && !hasLoadedFollowData) {
            try {
                const response = await fetchFollowItems();
                setFollowItems(response.follow_items);
                setHasLoadedFollowData(true);
            } catch (error) {
                console.error(t('fetchFollowItemsError'), error);
                showInfoBar(t('fetchFollowItemsFailed'));
            } finally {
                setIsFollowStateLoaded(true);
            }
        } else {
            setIsFollowStateLoaded(true);
        }
    }, [userInfo, hasLoadedFollowData, showInfoBar, t]);

    useEffect(() => {
        fetchInitialFollowState();
    }, [fetchInitialFollowState]);

    const isItemFollow = useCallback((currentUrlParams) => {
        return followItems.some(item => {
            const itemParams = new URLSearchParams(item.url_param);
            const currentParams = new URLSearchParams(currentUrlParams);
            
            for (let [key, value] of currentParams) {
                if (itemParams.get(key) !== value) {
                    return false;
                }
            }
            for (let [key, value] of itemParams) {
                if (currentParams.get(key) !== value) {
                    return false;
                }
            }
            return true;
        });
    }, [followItems]);

    const updateFollowItems = useCallback((newFollowItems) => {
        setFollowItems(newFollowItems);
    }, []);

    const triggerFollowAnimation = useCallback((isAdding) => {
        setFollowAnimationType(isAdding ? 'add' : 'remove');
        setTimeout(() => setFollowAnimationType(null), 500);
    }, []);

    const handleFollowAction = useCallback(async (followData) => {
        if (!userInfo) {
            showInfoBar(t('loginRequiredForFollow'));
            setShowLogin(true);
            updateModalState(true);
            return;
        }
    
        if (isFollowLoading) return;
    
        setIsFollowLoading(true);
        const isCurrentlyFollow = isItemFollow(followData.url_param);
        
        // Immediately trigger the animation
        triggerFollowAnimation(!isCurrentlyFollow);

        try {
            const response = isCurrentlyFollow
                ? await unfollowItem(followData)
                : await followItem(followData);
    
            if (response.follow_items) {
                setFollowItems(response.follow_items);
                setHasLoadedFollowData(true);
                showInfoBar(isCurrentlyFollow ? t('unfollowSuccess') : t('followSuccess'));
            } else {
                throw new Error(t('followOperationFailed'));
            }
        } catch (error) {
            console.error(t('followActionError'), error);
            if (error.followCountLimit) {
                showInfoBar(t('followCountLimit'));
            } else {
                showInfoBar(t('followActionFailed'));
            }
            // Revert the animation if there's an error
            triggerFollowAnimation(isCurrentlyFollow);
        } finally {
            setIsFollowLoading(false);
        }
    }, [userInfo, isFollowLoading, isItemFollow, showInfoBar, setShowLogin, updateModalState, t, triggerFollowAnimation]);
    
    return {
        followItems,
        isFollowStateLoaded,
        isFollowLoading,
        isItemFollow,
        handleFollowAction,
        updateFollowItems,
        followAnimationType,
        setFollowAnimationType
    };
};