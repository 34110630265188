import React, { useState } from 'react';
import { Share } from 'lucide-react';
import ShareModal from './ShareModal';

const ShareButton = ({ boardTitle, boardSubtitle, backgroundImageUrl, avatarUrl }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleShareClick = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
        <div 
        className="share-button"
        onClick={handleShareClick}
        aria-label="Share"
        >
        <Share size={20} />
        </div>
      {isModalOpen && <ShareModal 
        onClose={handleCloseModal} 
        boardTitle={boardTitle} 
        boardSubtitle={boardSubtitle}
        backgroundImageUrl={backgroundImageUrl}
        avatarUrl={avatarUrl}
        />}
    </>
  );
};

export default ShareButton;