import React from 'react';
import { useTranslation } from 'react-i18next';

const FollowTip = ({ onClose }) => {
  const { t } = useTranslation();

  return (
    <div className="follow-tip" onClick={onClose}>
      <div className="follow-tip-content">
        {t('followFromHere')}
      </div>
      <style jsx>{`
        .follow-tip {
          position: absolute;
          top: 40px;
          right: 20px;
          background-color: rgba(0, 0, 0, 0.67); /* 半透明の黒背景 */
          border-radius: 4px;
          padding: 10px;
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          z-index: 1000;
          cursor: pointer;
          transition: background-color 0.3s ease;
        }

        .follow-tip::before {
          content: '';
          position: absolute;
          bottom: 100%;
          right: 20px;
          border-left: 10px solid transparent;
          border-right: 10px solid transparent;
          border-bottom: 10px solid rgba(0, 0, 0, 0.67); /* 半透明の黒い三角形 */
        }

        .follow-tip-content {
          display: flex;
          align-items: center;
          font-size: 0.6em;
          color: #fff;
          gap: 5px;
        }
      `}</style>
    </div>
  );
};

export default FollowTip;
