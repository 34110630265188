// export const showInformationBar = (message, setShowInfoBar) => {
//   const infoBar = document.getElementById('info-bar');
//   infoBar.innerText = message;
//   setShowInfoBar(true);
//   setTimeout(() => {
//     setShowInfoBar(false);
//   }, 3000);
// };

export const handleNewItems = (responseData, setItems) => {
  if (responseData.result === 'already-exist' || responseData.result === 'add-board' || responseData.result === 'ready-generate') {
    const newItems = responseData.add_items.map(item => ({ ...item, isNew: true }));
    setItems((prevItems) => {
      // 新しいアイテムのIDリストを作成
      const newItemIds = new Set(newItems.map(item => item.id));
      // 既存のアイテムから新しいアイテムと同じIDを持つものを除外
      const filteredPrevItems = prevItems.filter(item => !newItemIds.has(item.id));
      // 新しいアイテムを先頭に追加
      return [...newItems, ...filteredPrevItems];
    });

    setTimeout(() => {
      setItems((prevItems) => prevItems.map(item => {
        if (item.isNew) {
          return { ...item, isNew: false };
        }
        return item;
      }));
    }, 3000);
  }
};