import { getAuthToken } from '../utils/authUtils';
import { getCurrentLanguage } from '../utils/languageUtils';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

console.log('API Endpoint:', API_ENDPOINT);

// ユーザーの認証状態に基づいてエンドポイントを選択する関数
const getEndpoint = (isAuthenticated, baseEndpoint) => {
  return isAuthenticated ? baseEndpoint : `${baseEndpoint}-guest`;
};

// すべてのリクエストに認証トークンを追加する共通のfetch関数
const fetchWithAuth = async (url, options = {}) => {
  let isAuthenticated = false;
  const token = await getAuthToken();
  const lang = getCurrentLanguage();
  
  if (token) {
    options.headers = {
      ...options.headers,
      'Authorization': `Bearer ${token}`
    };
    isAuthenticated = true;
  }

  // URLを動的に調整
  const adjustedUrl = url.replace('/card', getEndpoint(isAuthenticated, '/card'))
                         .replace('/comment', getEndpoint(isAuthenticated, '/comment'))
                         .replace('/tags', getEndpoint(isAuthenticated, '/tags'));
  
  // URLSearchParamsを使用して既存のパラメータを解析
  const urlObj = new URL(adjustedUrl, API_ENDPOINT);
  const searchParams = urlObj.searchParams;

  // lngパラメータが既に存在する場合は更新し、存在しない場合は追加
  searchParams.set('lng', lang === 'en' ? 'lng-en' : 'lng-jp');

  // 更新されたURLを構築
  const finalUrl = `${urlObj.origin}${urlObj.pathname}?${searchParams.toString()}`;

  const response = await fetch(finalUrl, options);
  
  if (!response.ok) {
    if (response.status === 401) {
      throw new Error('認証エラー');
    }

    let errorMessage = 'An error occurred';
    let errorCode = 'UNKNOWN_ERROR';

    try {
      const errorData = await response.json();
      errorMessage = errorData.message || errorMessage;
      errorCode = errorData.errorCode || errorCode;
    } catch (e) {
      // JSONとしてパースできない場合は、デフォルトのメッセージとコードを使用
    }
    
    const error = new Error(errorMessage);
    error.status = response.status;
    error.errorCode = errorCode;
    throw error;
  }

  // 204 No Content の場合は空のオブジェクトを返す
  if (response.status === 204) {
    return {};
  }

  return response.json();
};

//　クリップAPI

export const fetchItems = async (params = {}, signal) => {
  const queryParams = new URLSearchParams();
  
  for (const [key, value] of Object.entries(params)) {
    if (Array.isArray(value)) {
      value.forEach(v => queryParams.append(key, v));
    } else {
      queryParams.append(key, value);
    }
  }
  
  const url = `${API_ENDPOINT}/card?${queryParams.toString()}`;
  return fetchWithAuth(url, { signal });
};

// コメントAPI

export const fetchComments = async (itemId) => {
  return fetchWithAuth(`${API_ENDPOINT}/comment?item_id=${itemId}`);
};

// 新規クリップ登録API

export const submitNewUrl = async (url, boardId) => {
  if (!boardId) {
    throw new Error('Board ID is required');
  }

  return fetchWithAuth(`${API_ENDPOINT}/post-form`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ url, board_id: boardId }),
  });
};

// ログインAPI

export const loginUser = async (token) => {
  return fetchWithAuth(`${API_ENDPOINT}/login`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token }),
  });
};

export const logoutUser = async () => {
  // ログアウト処理のロジックをここに実装
  // 例: セッションを終了し、ローカルストレージをクリア
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({ success: true });
    }, 1000);
  });
};

// ユーザーデータ
export const updateUserData = async (userData) => {
  return fetchWithAuth(`${API_ENDPOINT}/userdata`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(userData),
  });
};


// 新しいアバターアップロード関数を追加
export const uploadAvatar = async (avatarData) => {
  return fetchWithAuth(`${API_ENDPOINT}/avatar-upload`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ avatar: avatarData }),
  });
};


// ボードの取得
export const fetchBoards = async () => {
  return fetchWithAuth(`${API_ENDPOINT}/board`);
};

// 新しいボードの作成
export const createBoard = async (boardName) => {
  return fetchWithAuth(`${API_ENDPOINT}/board`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ name: boardName }),
  });
};

// ボードの削除
export const deleteBoard = async (boardId) => {
  return fetchWithAuth(`${API_ENDPOINT}/board/${boardId}`, {
    method: 'DELETE',
  });
};

// ボードの更新（名前の変更など）
export const updateBoard = async (boardId, updatedData) => {
  return fetchWithAuth(`${API_ENDPOINT}/board/${boardId}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updatedData),
  });
};

// ボードにアイテム（クリップ）を追加
export const addItemToBoard = async (boardId, itemId) => {
  return fetchWithAuth(`${API_ENDPOINT}/board/${boardId}/items`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ itemId }),
  });
};

// ボードからアイテム（クリップ）を削除
export const removeItemFromBoard = async (boardId, itemId) => {
  return fetchWithAuth(`${API_ENDPOINT}/board/${boardId}/items`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ itemId }),
  });
};

// 特定のボードの詳細（含まれるアイテムなど）を取得
export const fetchBoardDetails = async (boardId) => {
  return fetchWithAuth(`${API_ENDPOINT}/board/${boardId}`);
};


// 通報と更新の共通関数
const reportOrUpdateItem = async (itemId, action, comment, boardId = '') => {
  return fetchWithAuth(`${API_ENDPOINT}/item`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ itemId, action, comment, boardId }),
  });
};

// 通報API
export const reportItem = async (itemId, comment) => {
  return reportOrUpdateItem(itemId, 'item-report', comment);
};

// 更新リクエストAPI
export const requestItemUpdate = async (itemId, comment, boardId = '') => {
  return reportOrUpdateItem(itemId, 'item-update', comment, boardId);
};

// 削除リクエストAPI
export const deleteItem = async (itemId, comment) => {
  return reportOrUpdateItem(itemId, 'item-delete', comment);
};


// フォローしているアイテムを取得
export const fetchFollowItems = async () => {
  return fetchWithAuth(`${API_ENDPOINT}/follow`);
};

export const followItem = async (followData) => {
  try {
    const response = await fetchWithAuth(`${API_ENDPOINT}/follow`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(followData),
    });
    return response;
  } catch (error) {
    // エラーオブジェクトにレスポンスの詳細情報を追加
    if (error.status === 400 && error.errorCode === 'FOLLOW_COUNT_LIMIT') {
      error.followCountLimit = true;
    }
    throw error;
  }
};

export const unfollowItem = async (followData) => {
  return fetchWithAuth(`${API_ENDPOINT}/follow`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(followData),
  });
};

// タグの取得
export const fetchTags = async () => {
  return fetchWithAuth(`${API_ENDPOINT}/tags`);
};


// 招待URLを取得するAPI
export const fetchInviteUrl = async (boardId) => {
  return fetchWithAuth(`${API_ENDPOINT}/invite/${boardId}`, {
    method: 'GET',
  });
};

// 招待コードを処理するAPI
export const processInvite = async (inviteCode) => {
  return fetchWithAuth(`${API_ENDPOINT}/invite`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ invite_code: inviteCode }),
  });
};

// export const leaveBoard = async (boardId) => {
//   return fetchWithAuth(`${API_ENDPOINT}/invite/${boardId}`, {
//     method: 'DELETE',
//   });
// };

export const leaveBoard = async (boardId, memberCode, memberName) => {
  return fetchWithAuth(`${API_ENDPOINT}/invite/${boardId}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ memberCode, memberName }),
  });
};


export const fetchClipData = async () => {
  return fetchWithAuth(`${API_ENDPOINT}/clip`);
};