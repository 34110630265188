import React, { useEffect, useRef } from 'react';

const Tips = ({ message, isVisible, anchorRef, preferredDirection = 'bottom' }) => {
  const tipsRef = useRef(null);

  useEffect(() => {
    const updatePosition = () => {
      if (isVisible && anchorRef.current && tipsRef.current) {
        const anchorRect = anchorRef.current.getBoundingClientRect();
        const tipsRect = tipsRef.current.getBoundingClientRect();
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;

        let top, left;
        const direction = preferredDirection === 'bottom' && anchorRect.bottom + tipsRect.height > windowHeight
          ? 'top'
          : preferredDirection;

        if (direction === 'bottom') {
          top = anchorRect.bottom;
        } else {
          top = anchorRect.top - tipsRect.height;
        }

        left = anchorRect.left + (anchorRect.width / 2) - (tipsRect.width / 2);
        if (left + tipsRect.width > windowWidth) {
          left = windowWidth - tipsRect.width - 10;
        } else if (left < 0) {
          left = 10;
        }

        tipsRef.current.style.top = `${top}px`;
        tipsRef.current.style.left = `${left}px`;
        tipsRef.current.className = `tips-container ${direction}`;
      }
    };

    updatePosition();
  }, [isVisible, anchorRef, preferredDirection]);

  if (!isVisible) return null;

  return (
    <div ref={tipsRef} className={`tips-container ${preferredDirection}`}>
      <div className="tips-content">
        <p>{message}</p>
      </div>
    </div>
  );
};

export default Tips;