import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchInviteUrl, leaveBoard } from '../services/api';
import { List, UserPlus } from 'lucide-react';
import ConfirmationModal from './ConfirmationModal'; // 新しく追加

const InviteModal = ({ isOpen, onClose, boardId }) => {
  const { t } = useTranslation();
  const [inviteUrl, setInviteUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showMemberList, setShowMemberList] = useState(false);
  const [memberDatas, setMemberDatas] = useState([]);
  const [selectedMember, setSelectedMember] = useState(null);
  const [showConfirmation, setShowConfirmation] = useState(false); // 新しく追加
  const [memberCountLimit, setMemberCountLimit] = useState(0); // 新しく追加

  useEffect(() => {
    if (isOpen && boardId) {
      setIsLoading(true);
      setError(null);
      fetchInviteUrl(boardId)
        .then(response => {
          if (response.inviteUrl) {
            const fullUrl = new URL(response.inviteUrl, window.location.origin).toString();
            setInviteUrl(fullUrl);
            setMemberDatas(response.memberDatas || []);
            setMemberCountLimit(response.memberCountLimit || 0); // 新しく追加
          } else {
            throw new Error('Invalid response from server');
          }
        })
        .catch(err => {
          setError(err.message || 'An error occurred while fetching the invite URL');
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isOpen, boardId]);

  const handleRemoveMember = async () => {
    if (!selectedMember) return;
    setShowConfirmation(true); // 確認モーダルを表示
  };

  const confirmRemoveMember = async () => {
    setIsLoading(true);
    try {
      await leaveBoard(boardId, selectedMember.member_code, selectedMember.member_name);
      setMemberDatas(prevMembers => prevMembers.filter(member => member.member_code !== selectedMember.member_code));
      setSelectedMember(null);
    } catch (err) {
      setError('Failed to remove member');
    } finally {
      setIsLoading(false);
    }
    setShowConfirmation(false); // 確認モーダルを閉じる
  };
  const handleMemberSelect = (member) => {
    setSelectedMember(selectedMember?.member_code === member.member_code ? null : member);
  };

  const renderMemberName = (member) => {
    if (member.member_name && member.member_name.trim() !== '') {
      return member.member_name;
    } else {
      return <span className="unset-name">{t('unsetName')}</span>;
    }
  };

  const toggleView = () => {
    setShowMemberList(!showMemberList);
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content invite-modal-content" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <h2>{showMemberList ? t('memberList') : t('inviteToBoard')}</h2>
          <div className="modal-actions">
            {showMemberList ? (
              <UserPlus
                size={20}
                onClick={toggleView}
                aria-label={t('showInviteUrl')}
              />
            ) : (
              <List
                size={20}
                onClick={toggleView}
                aria-label={t('showMemberList')}
              />
            )}
          </div>
        </div>
        {isLoading ? (
          <p>{t('loading')}</p>
        ) : error ? (
          <p className="error-message">{error}</p>
        ) : showMemberList ? (
          <>
            {memberDatas.length > 0 ? (
              <ul className="member-list">
                {memberDatas.map((member) => (
                  <li 
                    key={member.member_code} 
                    onClick={() => handleMemberSelect(member)}
                    className={selectedMember?.member_code === member.member_code ? 'selected' : ''}
                  >
                    {renderMemberName(member)}
                  </li>
                ))}
              </ul>
            ) : (
              <p className="no-members-message">{t('noMembersYet')}</p>
            )}
            <button 
              onClick={handleRemoveMember} 
              disabled={!selectedMember || isLoading || memberDatas.length === 0}
              className="remove-member-button"
            >
              {isLoading ? t('removing') : t('removeMember')}
            </button>
            <p className="member-count">{t('memberCount', { current: memberDatas.length, limit: memberCountLimit })}</p>
            <ConfirmationModal
              isOpen={showConfirmation}
              onClose={() => setShowConfirmation(false)}
              onConfirm={confirmRemoveMember}
              message={t('confirmRemoveMember', { memberName: selectedMember?.member_name || t('unsetName') })}
            />
          </>
        ) : (
          <>
            <p className="modal-description">{t('inviteUrlDescription')}</p>
            <input 
              type="text" 
              value={inviteUrl} 
              readOnly 
              className="modal-form-input"
            />
            <button onClick={() => navigator.clipboard.writeText(inviteUrl)}>
              {t('copyUrl')}
            </button>
            <p className="member-count">{t('memberCount', { current: memberDatas.length, limit: memberCountLimit })}</p>
          </>
        )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>{t('processing')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default InviteModal;