import React from 'react';

const BackgroundImage = ({ imageUrl, overlayOpacity = 0.5 }) => {
  const containerStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: 'hidden',
    zIndex: -1,
  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  };

  const overlayStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: `rgba(255, 255, 255, ${overlayOpacity})`,
  };

  return (
    <div style={containerStyle}>
      <img src={imageUrl} alt="Background" style={imageStyle} />
      <div style={overlayStyle}></div>
    </div>
  );
};

export default BackgroundImage;