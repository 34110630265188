import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TermOptionsModal from './TermOptionsModal';
import { Ellipsis } from 'lucide-react';

const TabTerm = ({ item, onActionSelect, isLoggedIn, isSubmitting, userInfo }) => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const hasTermData = item.term && item.term.length > 0;

  const mainTerms = hasTermData ? item.term.filter(term => term.type === 'main') : [];
  const relatedTerms = hasTermData ? item.term.filter(term => term.type !== 'main') : [];

  const renderTerms = (terms, title) => (
    <div>
      <h4 className="section-title">{title}</h4>
      {terms.map((term, idx) => (
        <div key={idx} className={`term-info ${term.is_end ? 'ended-term' : ''}`}>
          <p>
            {term.is_status === 'is_during' && <span className="ongoing">{t('ongoing')}</span>}
            {term.is_status === 'is_end_soon' && <span className="endsoon">{t('endSoon')}</span>}
            {term.is_status === 'is_end' && <span className="ended">{t('ended')}</span>}
            {term.is_status === 'is_coming' && <span className="coming">{t('comingSoon')}</span>}
          </p>
          <p className="term-date-time">
            <strong>{t('eventDateTime')}:</strong> {term.time}
            {term.time_detail && <span>{term.time_detail}</span>}
          </p>
          {term.location.length > 1 && (
            <p><strong>{t('location')}:</strong> {term.location}</p>
          )}
          {(term.participants.length > 1 || (term.participants.length === 1 && term.participants[0].length > 1)) && (
            <p><strong>{term.participants_label}:</strong> {term.participants.join(', ')}</p>
          )}
          {term.term_name.length > 1 && (
            <p><strong>{t('scheduleCategory')}:</strong> {term.term_name}</p>
          )}
        </div>
      ))}
    </div>
  );

  return (
    <div className="tab-content">
      {!hasTermData && <p className="no-content">{t('noScheduleInfo')}</p>}
      {mainTerms.length > 0 && renderTerms(mainTerms, t('mainEvent'))}
      {relatedTerms.length > 0 && renderTerms(relatedTerms, t('relatedEvents'))}
      {item.update_at && (
        <div className="term-update-text">
          <p>{t('aiExtractionDisclaimer')}</p>
          <p>{t('clipUpdateDate', { date: item.update_at })}</p>
        </div>
      )}
      {isLoggedIn && (
        <div className="term-options">
          <button className="options-button" onClick={() => setIsModalOpen(true)}><Ellipsis size={16} /></button>
        </div>
      )}
      <TermOptionsModal
        item={item}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onActionSelect={(action) => {
          setIsModalOpen(false);
          onActionSelect(action);
        }}
        isSubmitting={isSubmitting}
        userInfo={userInfo}
      />
    </div>
  );
};

export default TabTerm;