import React from 'react';
import { useTranslation } from 'react-i18next';
import { Rss } from 'lucide-react';

const RecommendedTags = ({ tags, handleNavigation }) => {
  const { t } = useTranslation();
  
  // Filter news tags and select up to 20
  const newsTagsPool = tags
    .filter(tag => tag.is_news === true)
    .slice(0, 20);

  // Randomly select 8 tags from the pool
  const shuffled = [...newsTagsPool].sort(() => 0.5 - Math.random());
  const recommendedTags = shuffled.slice(0, 8);

  if (recommendedTags.length === 0) {
    return null;
  }

  const getParamKey = (type) => {
    switch (type) {
      case 'location':
        return 'loc';
      case 'area':
        return 'area';
      case 'style':
        return 'style';
      default:
        return 'tag';
    }
  };

  return (
    <div className="recommended-tags">
      <h3>{t('recommendedTags')}</h3>
      <div className="recommended-tags-buttons">
        {recommendedTags.map(tag => {
          const paramKey = getParamKey(tag.type);
          const path = `${window.location.pathname}?${paramKey}=${encodeURIComponent(tag.name)}`;
          return (
            <button
              key={tag.id}
              className={`recommended-tag-button ${tag.type}-tag`}
              onClick={() => handleNavigation(path)}
            >
              {tag.name}
              {tag.is_news && <Rss size={8} style={{ marginLeft: '4px', verticalAlign: 'middle' }} />}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default RecommendedTags;