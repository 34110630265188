import { fetchAuthSession } from 'aws-amplify/auth';

export const getAuthToken = async () => {
  try {
    const { tokens } = await fetchAuthSession();
    return tokens?.idToken?.toString() || null;
  } catch (error) {
    if (error.name === 'UserUnAuthenticatedException') {
      // ユーザーが未認証の場合は静かに null を返す
      return null;
    }
    console.error('認証トークンの取得に失敗しました:', error);
    return null;
  }
};