import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';

const TabClipped = ({ item, clipData, onClose }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  if (!clipData || !clipData[item.sourceLink] || !clipData[item.sourceLink].clipers) {
    return <div className='noclip-data'>{t('noClipData')}</div>;
  }

  const clipers = clipData[item.sourceLink].clipers;

  const handleTableClick = (tableId) => {
    navigate(`${location.pathname}?tbl=${tableId}`);
    onClose(); // モーダルを閉じる
  };

  return (
    <div className="clipped-tables">
      <div className='clipped-section'>{t('clippedTables')}</div>
      <ul>
        {clipers.map((cliper, index) => (
          <li key={index} onClick={() => handleTableClick(cliper.table_id)} className="clipped-table-item">
            <div className="cliper-info">
              <img src={cliper.avatar_url} alt={`${cliper.user_name}'s avatar`} className="cliper-avatar" />
              <div className="cliper-details">
                <span className="table-name">{cliper.table_name}</span>
                <span className="user-name">
                  {cliper.user_name && cliper.user_name.trim() !== '' ? cliper.user_name : t('unsetName')}
                </span>
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default TabClipped;