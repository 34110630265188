import React, { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Image } from 'lucide-react';
import AvatarSelector from './AvatarSelector';

const UserDataUpdateModal = ({ isOpen, onClose, onUpdate, currentUserData }) => {
  const { t } = useTranslation();
  const [userData, setUserData] = useState({ username: '', avatar: '' });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [showAvatarSelector, setShowAvatarSelector] = useState(false);

  useEffect(() => {
    if (currentUserData) {
      setUserData({ 
        username: currentUserData.username || '', 
        avatar: currentUserData.avatarUrl || '' 
      });
    }
  }, [currentUserData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevData => ({ ...prevData, [name]: value }));
    setError('');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (userData.username.length === 0) {
      setError(t('enterUsername'));
      return;
    }
    setIsLoading(true);
    try {
      await onUpdate(userData);
      onClose();
    } catch (error) {
      console.error(t('failedToUpdateUserData'), error);
      setError(t('failedToUpdateUsername'));
    } finally {
      setIsLoading(false);
    }
  };

  const handleOverlayClick = useCallback((e) => {
    if (e.target === e.currentTarget) {
      onClose();
    }
  }, [onClose]);

  const handleAvatarSelect = (avatarId) => {
    setUserData(prevData => ({ ...prevData, avatar: avatarId }));
  };

  const handleAvatarUpdate = async (selectedAvatar) => {
    setIsLoading(true);
    try {
      await onUpdate({ ...userData, avatar: selectedAvatar });
      setShowAvatarSelector(false);
    } catch (error) {
      console.error(t('failedToUpdateAvatar'), error);
      setError(t('failedToUpdateAvatar'));
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={handleOverlayClick}>
      <div className="modal-content">
        {!showAvatarSelector ? (
          <>
            <div className="modal-header">
              <h2>{t('changeUsername')}</h2>
              <button 
                className="avatar-button"
                onClick={() => setShowAvatarSelector(true)}
              >
                <Image size={20} />
              </button>
            </div>
            <p className="modal-description">{t('editUserNameDescription')}</p>
            {error && <p className="error-message">{error}</p>}
            <form onSubmit={handleSubmit} className="modal-form">
              <input
                id="username"
                name="username"
                value={userData.username || ''}
                onChange={handleChange}
                disabled={isLoading}
                className="modal-form-input"
                maxLength={15}
                placeholder={t('enterUsernameWithin15Chars')}
              />
              <p className="char-count">{userData.username.length}/15</p>
              <button type="submit" disabled={isLoading}>
                {isLoading ? t('updating') : t('update')}
              </button>
            </form>
          </>
        ) : (
          <AvatarSelector
            currentAvatar={userData.avatar}
            onSelect={handleAvatarSelect}
            onUpdate={handleAvatarUpdate}
            onBack={() => setShowAvatarSelector(false)}
            isLoading={isLoading}
          />
        )}
        {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>{t('updatingUserData')}</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserDataUpdateModal;