export const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      region: process.env.REACT_APP_REGION,
      loginWith: {
        oauth: {
          domain: process.env.REACT_APP_COGNITO_DOMAIN,
          scopes: ['email', 'profile', 'openid'],
          redirectSignIn: [process.env.REACT_APP_REDIRECT_SIGN_IN_01,process.env.REACT_APP_REDIRECT_SIGN_IN_02],
          redirectSignOut: [process.env.REACT_APP_REDIRECT_SIGN_OUT_01,process.env.REACT_APP_REDIRECT_SIGN_OUT_02],
          responseType: 'code',
          providers: ['Google', 'Apple']
        }
      }
    },
  },
  API: {
    endpoints: [
      {
        name: 'api',      
        endpoint: process.env.REACT_APP_API_ENDPOINT,
        region: 'ap-northeast-1'
      },
    ]
  }
};