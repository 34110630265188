import React from 'react';
import { useTranslation } from 'react-i18next';

const TabOverview = ({ item }) => {
  const { t } = useTranslation();

  const renderTextWithLineBreaksAndLinks = (text) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;

    return text.split('\n').map((line, lineIndex) => {
      const parts = line.split(urlRegex);
      return (
        <React.Fragment key={lineIndex}>
          {parts.map((part, partIndex) => {
            if (part.match(urlRegex)) {
              return (
                <a
                  key={partIndex}
                  href={part}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {part}
                </a>
              );
            }
            return part;
          })}
          {lineIndex < text.split('\n').length - 1 && <br />}
        </React.Fragment>
      );
    });
  };

  return (
    <div className='overview-contents'>
      {item.overview ? (
        <>
          <p className="overview-text">
            {renderTextWithLineBreaksAndLinks(item.overview)}
          </p>
          {item.update_at && (
            <p className="overview-update-text">
              {t('clipUpdateDate', { date: item.update_at })}
            </p>
          )}
        </>
      ) : (
        <p className="no-content no-overview">{t('noOverview')}</p>
      )}
    </div>
  );
};

export default TabOverview;