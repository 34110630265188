import React from 'react';

const TabComments = ({ item, isLoading }) => (
  <div className="comments-container">
    <div className="comments-list">
      {isLoading ? (
        <p className="loading-content">コメントを取得中...</p>
      ) : item.comments.length > 0 ? (
        item.comments.map((comment, idx) => (
          <div key={idx} className="comment-item">
            <div className="comment-avatar"></div>
            <div className="comment-content">
              <div className="comment-header">
                <span className="comment-user">{comment.user}</span>
                <span className="comment-time">{comment.time || '1 hour ago'}</span>
              </div>
              <div className="comment-text">{comment.text}</div>
              <div className="comment-actions">
                <button>Like</button>
                <button>Reply</button>
              </div>
            </div>
          </div>
        ))
      ) : (
        <p className="no-content">コメントはありません。</p>
      )}
    </div>
  </div>
);

export default TabComments;