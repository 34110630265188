import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

const ReportItemModal = ({ isOpen, onClose, onSubmit, actionType }) => {
  const { t } = useTranslation();
  const [comment, setComment] = useState('');
  const maxLength = 200;

  if (!isOpen) return null;

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(actionType, comment);
    setComment('');
  };

  const handleChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= maxLength) {
      setComment(inputText);
    }
  };

  const getActionText = () => {
    switch (actionType) {
      case 'report':
        return t('report');
      case 'update':
        return t('updateRequest');
      case 'delete':
        return t('delete');
      default:
        return t('action');
    }
  };

  const getPlaceholder = () => {
    switch (actionType) {
      case 'report':
        return t('reportReasonPlaceholder');
      case 'update':
        return t('updateReasonPlaceholder');
      case 'delete':
        return t('deleteReasonPlaceholder');
      default:
        return t('commentPlaceholder');
    }
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{t('confirmAction', { action: getActionText() })}</h2>
        <form onSubmit={handleSubmit}>
          <textarea
            value={comment}
            onChange={handleChange}
            placeholder={getPlaceholder()}
            rows="4"
            maxLength={maxLength}
          />
          <p className="char-count">{t('charCount', { current: comment.length, max: maxLength })}</p>
          <div className="modal-buttons">
            <button type="button" onClick={onClose}>{t('cancel')}</button>
            <button type="submit">{getActionText()}</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ReportItemModal;