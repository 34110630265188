import React from 'react';
import { useTranslation } from 'react-i18next';
const APP_STAGE = process.env.REACT_APP_STAGE;

const TermOptionsModal = ({ item, isOpen, onClose, onActionSelect, isSubmitting, userInfo }) => {
  const { t } = useTranslation();

  if (!isOpen) return null;

  const handleAction = (action) => {
    if (!isSubmitting) {
      onActionSelect(action);
      onClose();
    }
  };

  const formatRemainTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return t('timeUntilAIUpdateAvailable', { hours, minutes });
  };

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{t('options')}</h2>
        <button onClick={() => handleAction('report')} disabled={isSubmitting}>
          {isSubmitting ? t('sending') : t('report')}
        </button>
        {item.display_update  && (
          <button 
            onClick={() => item.allow_update && handleAction('update')}
            className={item.allow_update ? '' : 'update-disabled'}
            disabled={!item.allow_update || isSubmitting}
          >
            {isSubmitting ? t('sending') : (item.allow_update ? t('autoUpdateWithAI') : t('pleaseWait'))}
            {!item.allow_update && !isSubmitting && (
              <span className="remain-time">
                {formatRemainTime(item.remain_time_to_update)}
              </span>
            )}
          </button>
        )}
        {userInfo.role === 'super-administrator' && (
          <button onClick={() => handleAction('delete')} disabled={isSubmitting}>
            {isSubmitting ? t('sending') : t('deleteDevOnly')}
          </button>
        )}
      </div>
    </div>
  );
};

export default TermOptionsModal;