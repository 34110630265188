import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const InviteResultModal = ({ isOpen, onClose, result }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  if (!isOpen) return null;

  const handleBoardNavigation = () => {
    navigate(`/?tbl=${result.boardId}`);
    onClose();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <h2>{t('inviteResult')}</h2>
        <p>{result.message}</p>
        {result.boardId && (
          <button onClick={handleBoardNavigation}>{t('goToBoard')}</button>
        )}
        <button onClick={onClose}>{t('close')}</button>
      </div>
    </div>
  );
};

export default InviteResultModal;