import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const BoardSelectionModal = ({ 
  isOpen, 
  onClose, 
  item, 
  onSuccess, 
  onError, 
  boards, 
  boardsLoading, 
  onAddToBoard, 
  onCreateBoard 
}) => {
  const { t } = useTranslation();
  const [selectedBoard, setSelectedBoard] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [newBoardName, setNewBoardName] = useState('');
  const [isCreatingNewBoard, setIsCreatingNewBoard] = useState(false);

  const ownerBoards = boards.filter(board => board.role === "owner");
  const memberBoards = boards.filter(board => board.role === "member");
  const canCreateNewBoard = ownerBoards.length < 3;

  useEffect(() => {
    if (isOpen) {
      setIsCreatingNewBoard(false);
      setSelectedBoard(null);
      setError('');
    }
  }, [isOpen]);

  const handleAddToBoard = async () => {
    if (!selectedBoard) {
      setError(t('selectTable'));
      return;
    }
    setIsLoading(true);
    onClose();
    setError('');
    try {
      const result = await onAddToBoard(selectedBoard, item.id);
      if (result.success) {
        onSuccess();
        // onClose();
      } else {
        throw result.error;
      }
    } catch (error) {
      console.error(t('failedToAddClipToTable'), error);
      if (error instanceof Error && error.message === t('authError')) {
        onError(t('authErrorMessage'));
      } else if (error.errorCode === 'ITEM_ALREADY_IN_BOARD') {
        onError(t('alreadyAddedToTable'));
      } else {
        onError(error.message || t('failedToAddToTable'));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateAndAddToNewBoard = async (e) => {
    e.preventDefault();
    if (!newBoardName.trim()) {
      setError(t('enterNewTableName'));
      return;
    }
    setIsLoading(true);
    setError('');
    try {
      const createResult = await onCreateBoard(newBoardName);
      if (createResult.success) {
        const addResult = await onAddToBoard(createResult.board.id, item.id);
        if (addResult.success) {
          onSuccess();
          setNewBoardName('');
          onClose();
        } else {
          throw new Error(addResult.error || t('failedToAddToNewTable'));
        }
      } else {
        throw new Error(createResult.error || t('failedToCreateNewTable'));
      }
    } catch (error) {
      console.error(t('failedToCreateAndAddToNewTable'), error);
      setError(error.message || t('failedToCreateAndAddToNewTable'));
      onError(error.message || t('failedToCreateAndAddToNewTable'));
    } finally {
      setIsLoading(false);
    }
  };

  const renderBoardList = (boardList, title) => (
    <>
      {(boardList.length != 0 && title != t('ownedBoards')) && 
      <div className='role-section-name'>{title}</div>
      }
      <ul>
        {boardList.map(board => {
          const isItemInBoard = board.items.includes(item.id);
          return (
            <li 
              key={board.id} 
              onClick={() => !isItemInBoard && setSelectedBoard(board.id)}
              className={`
                ${selectedBoard === board.id ? 'selected' : ''}
                ${isItemInBoard ? 'already-added' : ''}
              `}
            >
              {board.name}
              {isItemInBoard && <span className="already-added-text">（{t('alreadyAdded')}）</span>}
            </li>
          );
        })}
      </ul>
    </>
  );

  if (!isOpen) return null;

  return (
    <div className="modal-overlay" onClick={onClose}>
      <div className="modal-content" onClick={(e) => e.stopPropagation()}>
        <h2>{t('selectTable')}</h2>
        {error && <p className="error-message">{error}</p>}
        {!isCreatingNewBoard ? (
          <>
            {boardsLoading ? (
              <span className='loading-tables'>{t('loadingTables')}</span>
            ) : (
              <>
                {renderBoardList(ownerBoards, t('ownedBoards'))}
                {renderBoardList(memberBoards, t('memberBoards'))}
              </>
            )}
            <button 
              onClick={handleAddToBoard} 
              disabled={isLoading || !selectedBoard || boardsLoading}
            >
              {isLoading ? t('adding') : t('addToTable')}
            </button>
            <div className="change-button">
              <button 
                className="new-board-button"
                onClick={() => setIsCreatingNewBoard(true)}
                disabled={!canCreateNewBoard}
              >
                {canCreateNewBoard ? t('addToNewTable') : t('maxTablesReached')}
              </button>
            </div>
          </>
        ) : (
          <>
            <form onSubmit={handleCreateAndAddToNewBoard} className="modal-form">
              <input
                type="text"
                value={newBoardName}
                onChange={(e) => setNewBoardName(e.target.value.slice(0, 15))}
                placeholder={t('newTableNamePlaceholder')}
                className="modal-form-input"
                maxLength={15}
              />
              <p className="char-count">{newBoardName.length}/15</p>
              <button 
                type="submit"
                disabled={isLoading || !newBoardName.trim()}
              >
                {isLoading ? t('creating') : t('createAndAdd')}
              </button>
            </form>
            <div className="change-button">
              <button 
                className="new-board-button"
                onClick={() => setIsCreatingNewBoard(false)}
              >
                {t('selectExistingTable')}
              </button>
            </div>
          </>
        )}
        {/* {isLoading && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>{t('sending')}</p>
          </div>
        )} */}
      </div>
    </div>
  );
};

export default BoardSelectionModal;