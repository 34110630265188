import React, { useState, useCallback, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import TabTerm from './TabTerm';
import TabComments from './TabComments';
import TabImages from './TabImages';
import TabOverview from './TabOverview';
import BoardSelectionModal from './BoardSelectionModal';
import { removeItemFromBoard, reportItem, requestItemUpdate, deleteItem } from '../services/api';
import { DiamondMinus, PlusCircle, X, Link } from 'lucide-react';
import ReportItemModal from './ReportItemModal';
import ConfirmationModal from './ConfirmationModal';
import TabClipped from './TabClipped'; // 新しく作成するコンポーネント

const FullScreenCard = ({ 
  item, 
  onClose, 
  activeTab, 
  setActiveTab, 
  handleCommentSubmit, 
  isCommentsLoading, 
  handleImageClick,
  isLoggedIn,
  setShowLogin,
  showInfoBar,
  boards,
  boardsLoading,
  onAddToBoard,
  onCreateBoard,
  isBoardOwner,
  isBoardMember,
  onRemoveFromBoard,
  setShouldFetch,
  updateModalState,
  isFullScreenCardOpen,
  userInfo,
  clipData,
  handleNavigation
}) => {
  const { t } = useTranslation();
  const [imgError, setImgError] = useState(false);
  const [isBoardModalOpen, setIsBoardModalOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const [isRemoving, setIsRemoving] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [currentActionType, setCurrentActionType] = useState(null);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSwipe = (eventData) => {
    const tabs = ['term', 'clipped'];
    const currentIndex = tabs.indexOf(activeTab);
    let newIndex = currentIndex;

    if (eventData.dir === 'Left') {
      newIndex = (currentIndex + 1) % tabs.length;
    } else if (eventData.dir === 'Right') {
      newIndex = (currentIndex - 1 + tabs.length) % tabs.length;
    }

    setActiveTab(tabs[newIndex]);
  };

  useEffect(() => {
    updateModalState(true);
    setIsVisible(true);
    return () => {
      updateModalState(false);
      setIsVisible(false);
    };
  }, [updateModalState]);

  const handleBackgroundClick = (e) => {
    if (e.target.classList.contains('fullscreen-card')) {
      onClose();
    }
  };

  const swipeHandlers = useSwipeable({
    onSwipedLeft: (eventData) => {
      if (isFullScreenCardOpen) {
        handleSwipe(eventData);
      }
    },
    onSwipedRight: (eventData) => {
      if (isFullScreenCardOpen) {
        handleSwipe(eventData);
      }
    },
    trackMouse: true
  });

  const handleHeaderClick = () => {
    if (item.sourceLink) {
      window.open(item.sourceLink, '_blank');
    }
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case 'term':
        return <TabTerm 
          item={item} 
          onActionSelect={handleActionSelect}
          isLoggedIn={isLoggedIn}
          isSubmitting={isSubmitting}
          userInfo={userInfo}
        />
      case 'comments':
        return <TabComments item={item} isLoading={isCommentsLoading} />;
      case 'images':
        return <TabImages item={item} handleImageClick={handleImageClick} />;
      case 'overview':
        return <TabOverview item={item} />;
      case 'clipped': // 新しく追加
        return <TabClipped item={item} clipData={clipData} onClose={onClose} />;
      default:
        return null;
    }
  };

  const renderCommentForm = useCallback(() => {
    if (isLoggedIn) {
      return (
        <form onSubmit={handleCommentSubmit} className="comment-form active">
          <input
            type="text"
            name="comment"
            placeholder={t('enterComment')}
          />
          <button type="submit">{t('submit')}</button>
        </form>
      );
    } else {
      return (
        <div className="comment-form active login-prompt">
          <p>{t('loginToComment')}</p>
        </div>
      );
    }
  }, [isLoggedIn, handleCommentSubmit, t]);

  const openBoardModal = useCallback(() => {
    if (isLoggedIn) {
      setIsBoardModalOpen(true);
    } else {
      showInfoBar(t('loginRequiredForAddToTable'));          
      setShowLogin(true);
    }
  }, [isLoggedIn, setShowLogin, showInfoBar, t]);

  const handleRemoveFromBoard = useCallback(async () => {
    if (!isLoggedIn) {  
      setShowLogin(true);
      return;
    }
    setIsConfirmModalOpen(true);
  }, [isLoggedIn, setShowLogin]);

  const confirmRemoveFromBoard = useCallback(async () => {
    setIsConfirmModalOpen(false);
    setIsRemoving(true);

    showInfoBar(t('clipRemovedFromTable'));
    onRemoveFromBoard(item.id);
    onClose();

    try {

      const searchParams = new URLSearchParams(location.search);
      const boardId = searchParams.get('tbl');
  
      if (!boardId) {
        showInfoBar(t('boardIdNotFound'));
        return;
      }
  
      const result = await removeItemFromBoard(boardId, item.id);
      console.log('Remove item result:', result);
  
    } catch (error) {
      console.error(t('failedToRemoveClip'), error);
      showInfoBar(t('failedToRemoveFromTable'));
    } finally {
      setIsRemoving(false);
    }
  }, [location, item.id, showInfoBar, onClose, onRemoveFromBoard, t]);

  const renderBottomContent = useCallback(() => {
    if (activeTab === 'comments') {
      return renderCommentForm();
    } else {
      return (
        <div className="main-buttons">
          <div className="button-slot left">
            {(isBoardOwner || isBoardMember) && (
              <button 
                onClick={handleRemoveFromBoard} 
                className="remove-from-board-button"
              >
                <DiamondMinus size={18} className="fullscreen-bottom-button-icon" />
              </button>
            )}
          </div>
          <div className="button-slot center">
            <button 
              onClick={openBoardModal} 
              className="add-to-board-button"
            >
              <PlusCircle size={18} className="fullscreen-bottom-button-icon" />
              {t('addToTable')}
            </button>
          </div>
          <div className="button-slot right">
            <button 
              onClick={onClose} 
              className="close-button"
            >
              <X size={18} className="fullscreen-bottom-button-icon" />
            </button>
          </div>
        </div>
      );
    }
  }, [activeTab, renderCommentForm, isBoardOwner, isBoardMember, handleRemoveFromBoard, openBoardModal, onClose, t]);

  const handleBoardSelectionSuccess = useCallback(() => {
    showInfoBar(t('addedToTable'));
  }, [showInfoBar, t]);

  const handleBoardSelectionError = useCallback((errorMessage) => {
    showInfoBar(errorMessage);
  }, [showInfoBar]);

  const closeBoardModal = useCallback(() => {
    setIsBoardModalOpen(false);
  }, []);

  const handleReport = useCallback(async (comment) => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }

    setIsSubmitting(true);
    try {
      await reportItem(item.id, comment);
      showInfoBar(t('reportSubmitted'));
      onClose();
    } catch (error) {
      console.error(t('failedToReportItem'), error);
      showInfoBar(t('failedToSubmitReport'));
    } finally {
      setIsSubmitting(false);
    }
  }, [item.id, isLoggedIn, setShowLogin, showInfoBar, onClose, t]);

  const handleUpdate = useCallback(async (comment) => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }

    setIsSubmitting(true);
    try {
      const searchParams = new URLSearchParams(location.search);
      const boardId = searchParams.get('tbl') || '';

      await requestItemUpdate(item.id, comment, boardId);
      showInfoBar(t('updateRequestSubmitted'));
      setShouldFetch(true);
      onClose();
    } catch (error) {
      console.error(t('failedToRequestItemUpdate'), error);
      showInfoBar(t('failedToSubmitUpdateRequest'));
    } finally {
      setIsSubmitting(false);
    }
  }, [item.id, isLoggedIn, setShowLogin, showInfoBar, setShouldFetch, onClose, location.search, t]);

  const handleDelete = useCallback(async (comment) => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }

    setIsSubmitting(true);
    try {
      await deleteItem(item.id, comment);
      showInfoBar(t('deleteRequestSubmitted'));
      setShouldFetch(true);
      onClose();
    } catch (error) {
      console.error(t('failedToRequestItemDelete'), error);
      showInfoBar(t('failedToSubmitDeleteRequest'));
    } finally {
      setIsSubmitting(false);
    }
  }, [item.id, isLoggedIn, setShowLogin, showInfoBar, setShouldFetch, onClose, t]);

  const handleActionSelect = useCallback((actionType) => {
    if (!isLoggedIn) {
      setShowLogin(true);
      return;
    }
    setCurrentActionType(actionType);
    setIsReportModalOpen(true);
  }, [isLoggedIn, setShowLogin]);

  const handleActionSubmit = useCallback((actionType, comment) => {
    setIsReportModalOpen(false);
    switch (actionType) {
      case 'report':
        handleReport(comment);
        break;
      case 'update':
        handleUpdate(comment);
        break;
      case 'delete':
        handleDelete(comment);
        break;
      default:
        console.error(t('invalidActionType'));
    }
  }, [handleReport, handleUpdate, handleDelete, t]);

  const handleTagClick = useCallback((tag, isLocationTag) => {
    const paramKey = isLocationTag ? 'loc' : 'tag';
    const path = `${location.pathname}?${paramKey}=${encodeURIComponent(tag)}${item.is_sensitive ? '&is_sensitive=true' : ''}`;
    handleNavigation(path);
    onClose();
  }, [item.is_sensitive, handleNavigation, onClose, location.pathname]);


  const renderTag = (tag, isLocationTag) => (
    <span
      key={tag}
      onClick={() => handleTagClick(tag, isLocationTag)}
      className={`tag-link ${isLocationTag ? 'location-tag' : 'normal-tag'}`}
    >
      {item.is_sensitive ? `${tag} 18+` : tag}
    </span>
  );

  return (
    <div className={`fullscreen-card ${isVisible ? 'visible' : ''}`} onClick={handleBackgroundClick}>
      <div className="fullscreen-card-content" onClick={(e) => e.stopPropagation()} {...swipeHandlers}>
        {(isRemoving || isSubmitting) && (
          <div className="loading-overlay">
            <div className="loading-spinner"></div>
            <p>{t('sending')}</p>
          </div>
        )}
        <div className="fullscreen-card-content-inner">
          <div className="card-content-scroll">
            <div className="card-header" onClick={handleHeaderClick} style={{cursor: 'pointer'}}>
              {item.image && !imgError ? (
                <img
                  src={item.image}
                  alt={item.title}
                  onError={() => setImgError(true)}
                />
              ) : (
                <img src='./images/other/cover-noimage.png'/>
                // <div className="placeholder"></div>
              )}
            </div>
            <div className="card-title">{item.title}</div>
            {item.overview && item.overview.length !== 0 && (
              <div className="card-overview">“{item.overview}”</div>
            )}
            <div className="card-tags">
              {item.tags && item.tags.map(tag => renderTag(tag, false))}
              {item.location_tags && item.location_tags.map(tag => renderTag(tag, true))}
            </div>
            <div className="card-source-info">
                  <a href={item.sourceLink} target="_blank" rel="noopener noreferrer">
                    <Link size={8} style={{ marginRight: '4px' }} />
                    {item.sourceName}
                  </a>
            </div>            
            <div className="card-content">
              <div className="tabs">
                <button className={activeTab === 'term' ? 'active' : ''} onClick={() => setActiveTab('term')}>{t('scheduleTab')}</button>
                {/* <button className={activeTab === 'overview' ? 'active' : ''} onClick={() => setActiveTab('overview')}>{t('overviewTab')}</button> */}
                <button className={activeTab === 'clipped' ? 'active' : ''} onClick={() => setActiveTab('clipped')}>{t('clippedTab')}</button>
              </div>
              <div className="tab-content">
                {renderTabContent()}
              </div>
            </div>
          </div>
          {renderBottomContent()}
        </div>
      </div>
      <ReportItemModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        onSubmit={handleActionSubmit}
        actionType={currentActionType}
      />      
      <BoardSelectionModal
        isOpen={isBoardModalOpen}
        onClose={closeBoardModal}
        item={item}
        onSuccess={handleBoardSelectionSuccess}
        onError={handleBoardSelectionError}
        boards={boards}
        boardsLoading={boardsLoading}
        onAddToBoard={onAddToBoard}
        onCreateBoard={onCreateBoard}
      />
      <ConfirmationModal
        isOpen={isConfirmModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        onConfirm={confirmRemoveFromBoard}
        message={t('confirmRemoveClipFromTable')}
      />  
    </div>
  );
};

export default FullScreenCard;