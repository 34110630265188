// src/hooks/useBoard.js
import { useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { fetchBoards, createBoard, updateBoard, deleteBoard } from '../services/api';

export const useBoard = (showInfoBar, navigate) => {
  const { t } = useTranslation();
  const [boards, setBoards] = useState([]);
  const [boardsLoading, setBoardsLoading] = useState(false);
  const [boardTitle, setBoardTitle] = useState('');
  const [boardDescription, setBoardDescription] = useState('');
  const [boardSubtitle, setBoardSubtitle] = useState('');
  const [boardMemberCount, setBoardMemberCount] = useState('');
  const [avatarUrl, setAvatarUrl] = useState('');
  const [isBoardOwner, setIsBoardOwner] = useState(false);
  const [isBoardMember, setIsBoardMember] = useState(false);
  const [tagRank, setTagRank] = useState(null);
  const [baseTag, setBaseTag] = useState('');

  const clearBoardInfo = useCallback(() => {
    setBoardTitle('');
    setBoardDescription('');
    setBoardSubtitle('');
    setBoardMemberCount('');
    setAvatarUrl('');
    setIsBoardOwner(false);
    setIsBoardMember(false);
  }, []);

  const loadBoards = useCallback(async () => {
    setBoardsLoading(true);
    try {
      const fetchedBoards = await fetchBoards();
      setBoards(fetchedBoards);
    } catch (error) {
      console.error(t('fetchBoardsError'), error);
    } finally {
      setBoardsLoading(false);
    }
  }, [t]);

  const handleCreateBoard = useCallback(async (boardName) => {
    try {
      const newBoard = await createBoard(boardName);
      await loadBoards();
      return { success: true, board: newBoard };
    } catch (error) {
      console.error(t('createBoardError'), error);
      return { success: false, error };
    }
  }, [loadBoards, t]);

  // const handleBoardUpdate = useCallback(async (boardId, newBoardData) => {
  //   try {
  //     await updateBoard(boardId, newBoardData);
  //     showInfoBar(t('tableDataUpdated'));
  //     await loadBoards();
  //     return true;
  //   } catch (error) {
  //     console.error(t('tableDataUpdateError'), error);
  //     showInfoBar(t('tableDataUpdateFailed'));
  //     return false;
  //   }
  // }, [showInfoBar, loadBoards, t]);

  // const handleBoardDelete = useCallback(async (boardId) => {
  //   try {
  //     await deleteBoard(boardId);
  //     showInfoBar(t('tableDeleted'));
  //     clearBoardInfo();
  //     loadBoards();
  //     navigate('/');
  //     return true;
  //   } catch (error) {
  //     console.error(t('tableDeleteError'), error);
  //     showInfoBar(t('tableDeleteFailed'));
  //     return false;
  //   }
  // }, [showInfoBar, clearBoardInfo, loadBoards, navigate, t]);

  const setBoardInfo = useCallback((data) => {
    setBoardTitle(data.boardTitle || '');
    setBoardDescription(data.boardDescription || '');
    setBoardSubtitle(data.subTitle || '');
    setBoardMemberCount(data.memberCount || '');
    setAvatarUrl(data.imageUrl || '');
    setIsBoardOwner(data.isBoardOwner || false);
    setIsBoardMember(data.isBoardMember || false);
    setTagRank(data.tag_rank || null);
    setBaseTag(data.base_tag || '');
  }, []);

  return {
    boards,
    boardsLoading,
    boardTitle,
    boardDescription,
    boardSubtitle,
    boardMemberCount,
    avatarUrl,
    isBoardOwner,
    isBoardMember,
    tagRank,
    baseTag,
    loadBoards,
    handleCreateBoard,
    // handleBoardUpdate,
    // handleBoardDelete,
    setBoardInfo,
    clearBoardInfo
  };
};