export const amplifyTheme = {
    name: 'custom-theme',
    tokens: {
      colors: {
        brand: {
          primary: {
            10: { value: '#fff' },
            80: { value: '#000' },
            90: { value: '#31b6f3' },
            100: { value: '#2a2879' },
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: { value: '#000' },
              borderColor: { value: '#000' },
            },
            _hover: {
              color: { value: '{colors.brand.primary.90}' },
            },
            _active: {
              color: { value: '{colors.brand.primary.90}' },
              borderColor: { value: '{colors.brand.primary.90}' },
            },
          },
        },      
        button: {
          primary: {
            backgroundColor: { value: '{colors.brand.primary.80}' },
            _hover: {
              backgroundColor: { value: '{colors.brand.primary.90}' },
            },
            _active: {
              backgroundColor: { value: '{colors.brand.primary.100}' },
            },
          },
          link: {
            color: { value: '{colors.link.primary}' },
            _hover: {
              color: { value: '{colors.link.hover}' },
              textDecoration: { value: 'underline' },
            },
          },
        },
      },
    },
  };